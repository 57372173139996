import * as PIXI from 'pixi.js';

import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET, buyFeatureTextStyle } from '../config';
import AutoResizeText from '../text/autoResizeText';

class BuyFeatureBtnIcon extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  constructor() {
    super();
    this.visible = true;
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));

    return btn;
  }

  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('buyFeatureBtn'), buyFeatureTextStyle);
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);

    return text;
  }
}

export default BuyFeatureBtnIcon;
