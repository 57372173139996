export const CHAIN_TEXT_ANIMATION_IN_DURATION = 450;

export const CHAIN_TEXT_ANIMATION_IN_START_SCALE = 8.0;
export const CHAIN_TEXT_ANIMATION_IN_END_SCALE = 1;

const CHAIN_TEXT_ANIMATION_IN_START_ANGLE = 180;
export const CHAIN_TEXT_ANIMATION_IN_START_RADIAN = (CHAIN_TEXT_ANIMATION_IN_START_ANGLE * Math.PI) / 180;

export const CHAIN_TEXT_ANIMATION_SHOW_WAITING_DURATION = 500;

export const CHAIN_TEXT_ANIMATION_OUT_DURATION = 450;
