import { setCoinValue, setSlotConfig } from '.';
import { IBetSettings } from './d';

export const getBetsSetting = (): IBetSettings => {
  const { lineSet, clientSettings } = setSlotConfig();

  const minBet = (clientSettings.coinAmounts.default[0] ?? 0) as number;
  const maxBet = (clientSettings.coinAmounts.default[clientSettings.coinAmounts.default.length - 1] ?? 0) as number;
  const bets = clientSettings.coinAmounts.default;
  const { quick } = clientSettings.coinAmounts;
  const coinValue = setCoinValue();

  const minBetCount = (minBet * lineSet.coinAmountMultiplier * coinValue) / 100;
  const maxBetCount = (maxBet * lineSet.coinAmountMultiplier * coinValue) / 100;

  return {
    minBet,
    maxBet,
    bets,
    quick,
    coinValue,
    minBetCount,
    maxBetCount,
  };
};
