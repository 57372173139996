import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { IUserBalance } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql } from '../../gql/query';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance;
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });
  const version = window.__ENV__?.APP_VERSION ?? 'develop';
  const gameRulesDesc: string[] = t('infoGameRules', {
    returnObjects: true,
  });

  return (
    <div>
      <h1 className={styles.title}>{t('infoGameRulesTitle')}</h1>
      <div className={styles.row}>
        <div className={styles.col}>
          {gameRulesDesc
            .filter((_) => isBuyFeatureEnabled)
            .map((v) => (
              <p className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
            ))}
        </div>
      </div>

      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules2', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules3', {
              maxBet,
            }),
          }}
        />
      </div>
      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules4', {
              rtp: '96.52%',
            }),
          }}
        />
      </div>
      {setSlotConfig().isBuyFeatureEnabled && (
        <div className={`${styles.p} ${styles.center}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtpBuy: '96.63%',
              }),
            }}
          />
        </div>
      )}
      <div
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRulesVersion', {
            version,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
