import { ReelSet } from '../global';
import { Icon } from '../slotMachine/d';

import { getIconById } from './helper';

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom = random - 1 < 0 ? reelSet.layout[index]!.length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index]![prevRandom]!);
    }),
    // base position
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index]![random]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 1) % reelSet.layout[index]!.length;
      return getIconById(icons, reelSet.layout[index]![nextRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 2) % reelSet.layout[index]!.length;
      return getIconById(icons, reelSet.layout[index]![nextRandom]!);
    }),
  ];
  return spinResult;
};
