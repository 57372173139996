import _ from 'lodash';

import { ISongs, audioSprite } from './sprite.generated';

export * from './sprite.generated';

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.SONG_025_01_BaseGameBGM_Base]: 0.3,
  [ISongs.SONG_025_02_BaseGameBGM_Melo]: 0.3,
  [ISongs.SONG_025_03_FreeSpinBGM]: 0.3,
  [ISongs.SONG_025_04_TotalWinBanner]: 0.3,
  [ISongs.SONG_025_05_BigWin_Intro]: 0.3,
  [ISongs.SONG_025_06_BigWin_Loop]: 0.3,
  [ISongs.SONG_025_07_BigWin_END]: 0.3,
  [ISongs.SONG_025_11_Scatter_01]: 0.3,
  [ISongs.SONG_025_12_Scatter_02]: 0.3,
  [ISongs.SONG_025_13_Scatter_03]: 0.3,
  [ISongs.SONG_025_14_LongSpin]: 0.3,
  [ISongs.SONG_025_15_LongSpin_End]: 0.3,
  [ISongs.SONG_025_16_Chain]: 0.3,
  [ISongs.SONG_025_17_Hammer]: 0.3,
  [ISongs.SONG_025_18_Piyo]: 0.3,
  [ISongs.SONG_025_19_SpinStop]: 0.3,
  [ISongs.SONG_FeatureTrigger]: 0.3,
  [ISongs.SONG_SFX_BuyFeature]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_End]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SONG_SFX_UI_AutoSpin]: 0.3,
  [ISongs.SONG_SFX_UI_BetChange]: 0.3,
  [ISongs.SONG_SFX_UI_Close]: 0.3,
  [ISongs.SONG_SFX_UI_General]: 0.3,
  [ISongs.SONG_SFX_UI_Hover]: 0.3,
  [ISongs.SONG_SFX_UI_MaxBet]: 0.3,
  [ISongs.SONG_SFX_UI_MenuOpen]: 0.3,
  [ISongs.SONG_SFX_UI_SpinStart]: 0.3,
  [ISongs.SONG_SFX_Win_Big]: 0.3,
  [ISongs.SONG_SFX_Win_Epic]: 0.3,
  [ISongs.SONG_SFX_Win_Great]: 0.3,
  [ISongs.SONG_SFX_Win_Mega]: 0.3,
  [ISongs.SONG_Yocho_Phoenix]: 0.3,
};

const audioSpriteGaps: { [key in ISongs]?: number } = {};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
