import { Application, Container, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import { setProgress } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, getCssVariable, nextTick } from '../../utils';
import { BgmControl } from '../bgmControl/bgmControl';
import { eventManager } from '../config';
import SoundButton from '../controlButtons/soundBtn';
import { UiButton } from '../ui/uiButton';

import Carousel from './Carousel';

export class IntroScreen {
  private readonly application: Application;

  private static introScreen: IntroScreen;

  public static init = (application: Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new Sprite(Texture.from(ResourceTypes.introBg));

  private backGroundContainer = new Container();

  private container = new Container();

  private playBtn: UiButton;

  private soundBtn: SoundButton;

  private carousel: Carousel;

  private boundResize = this.resize.bind(this);

  constructor(application: Application) {
    this.application = application;

    this.background.anchor.set(0.5);
    this.backGroundContainer.addChild(this.background);

    this.container.addChild(this.backGroundContainer);

    this.playBtn = this.initPlayButton();
    this.soundBtn = new SoundButton();
    this.carousel = new Carousel(introContents);

    this.container.addChild(this.carousel, this.playBtn, this.soundBtn);
    this.application.stage.addChild(this.container);

    eventManager.addListener(EventTypes.RESIZE, this.boundResize);
  }

  private initPlayButton(): UiButton {
    const playButton = new UiButton('play');
    playButton.buttonSprite.anchor.set(1, 0.5);
    playButton.interactive = true;

    const clickCallback = () => {
      if (AudioApi.restrictionChangedOnIntroScreen) return;
      AudioApi.restrictionChangedOnIntroScreen = true;

      nextTick(() => {
        this.destroy();
        setProgress({ ...setProgress(), wasLoaded: true });
        //if (!AudioApi.isInitialized) {
        //  eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
        //}
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: [],
          onChangeRestriction: () => {
            BgmControl.handleChangeRestriction();
          },
        });
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    playButton.on('click', clickCallback);
    playButton.on('touchend', clickCallback);

    return playButton;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backGroundContainer.x = width / 2;
    this.backGroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backGroundContainer.scale.set(height / this.background.height);
    } else {
      this.backGroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);

    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));

    this.playBtn.buttonSprite.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;
    this.playBtn.setSize(calcPercentage(width, 20));

    this.soundBtn.buttonSprite.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));

    this.carousel.setSize(width, heightWithBottomGap, height - heightWithBottomGap);

    if (isPortraitMode) {
      this.soundBtn.buttonSprite.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.setSize(calcPercentage(width, 40));
      this.playBtn.buttonSprite.anchor.set(0.5, 0);
      this.playBtn.y = this.carousel.getBounds().y + this.carousel.height + 10;
      this.playBtn.x = width / 2;
    }

    this.application.renderer.resize(width, height);
  }
  private destroy() {
    eventManager.removeListener(EventTypes.RESIZE, this.boundResize);
    this.container.destroy({ children: true });
  }
}
