import { Graphics, Sprite } from 'pixi.js';

import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';

import { SCENE_CHANGE_BACKGROUND_COLOR, sceneChangeBaseSpin, sceneChangeFreeSpin } from './config';

class SceneChange extends ViewContainer {
  private rect: Graphics;

  constructor() {
    super();
    this.visible = false;

    this.rect = this.initRect();
    this.addChild(this.rect);

    eventManager.addListener(EventTypes.SCENE_CHANGE_DOWN, this.startFadeAnimation.bind(this, sceneChangeFreeSpin));
    eventManager.addListener(EventTypes.SCENE_CHANGE_UP, this.startFadeAnimation.bind(this, sceneChangeBaseSpin));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initRect() {
    const rect = new Graphics();
    rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    rect.drawRect(0, 0, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT);

    return rect;
  }

  private getFadeAnimation(object: Sprite | Graphics, duration: number, begin: number, target: number) {
    const animation = new Tween({
      object: object,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  private startFadeAnimation(
    animationParam: {
      fadeIn: { duration: number; beginValue: number; targetValue: number };
      fadeOut: { duration: number; beginValue: number; targetValue: number };
    },
    callbackFunction?: () => void,
  ) {
    this.visible = true;
    const animationChain = new AnimationChain();
    const fadeIn = this.getFadeAnimation(
      this.rect,
      animationParam.fadeIn.duration,
      animationParam.fadeIn.beginValue,
      animationParam.fadeIn.targetValue,
    );
    const fadeOut = this.getFadeAnimation(
      this.rect,
      animationParam.fadeOut.duration,
      animationParam.fadeOut.beginValue,
      animationParam.fadeOut.targetValue,
    );

    if (callbackFunction) {
      fadeOut.addOnStart(() => {
        callbackFunction();
      });
    }

    animationChain.appendAnimation(fadeIn);
    animationChain.appendAnimation(fadeOut);

    animationChain.start();
  }

  override resize(width: number, height: number): void {
    this.rect.width = width;
    this.rect.height = height;
  }
}
export default SceneChange;
