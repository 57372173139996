import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, SlotId, WildSymbolLifeCount } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode, setIsTurboSpin, setNextResult, setScatterPositions } from '../../gql';
import { isNotNullOrUndefined } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { StrictSpine } from '../components/spine/spine';
import { ViewContainer } from '../components/viewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SlotMachineState, eventManager } from '../config';
import { IWinLine } from '../d';
import { Hammer } from '../hammer/hammer';
import { MoleSpinAnimation } from '../spin/moleSpinAnimation';
import { HAMMER_ANIMATION_DURATION, HAMMER_ANIMATION_REEL_DELAY } from '../winAnimations/config';

import {
  baseGameScatterStopSoundList,
  freeSpinsScatterStopSoundList,
  moleSlotScales,
  moleSlotXPositionOffsets,
  moleSlotYPositions,
} from './config';
import MoleSlot from './moleSlot';

class MoleSlotsContainer extends ViewContainer {
  public slots: MoleSlot[] = [];

  public spinAnimations: MoleSpinAnimation[] = [];

  private hammerBeatenAnimations: Animation[] = [];

  private winAnimation?: Animation;

  private scatterWinAnimation?: Animation;

  private spineAnimation?: Animation;

  private anticipationEffectAnimations: Animation[] = [];

  private isTurboSpin = false;

  private anticipationReelId = REELS_AMOUNT;
  constructor() {
    super();

    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideStopSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipAnimations.bind(this));
    eventManager.addListener(EventTypes.START_HIT_HAMMER_ANIMATION, this.onStartHitHammerAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_CHAIN_STOP_SLOTS_DISPLAY, this.onShowChainStopSlots.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopSlots.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
  }

  private rollbackReels(spinResult: SlotId[]): void {
    this.skipAnimations();
    this.spinAnimations = [];
    this.hammerBeatenAnimations = [];

    this.showStopSlots(spinResult);
  }

  private clearSlots() {
    this.slots.forEach((slot) => {
      slot.skip();
    });
    this.removeChild(...this.slots);
    this.slots = [];
  }

  private initSlots(spinResult: SlotId[]) {
    this.clearSlots();
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = new MoleSlot(spinResult[i * REELS_AMOUNT + j]!);

        symbol.y = moleSlotYPositions[i]!;
        symbol.x = REEL_WIDTH * j + REEL_WIDTH / 2 + moleSlotXPositionOffsets[i]![j]!;
        symbol.scale.set(moleSlotScales[i], moleSlotScales[i]);
        this.addChild(symbol);
        this.slots.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private showStopSlots(spinResult: SlotId[], lifeCounts?: number[]) {
    this.initSlots(spinResult);
    this.slots.forEach((slot, position) => {
      slot.visible = true;
      slot.idle = true;
      if (lifeCounts && lifeCounts[position]! > 0 && slot.getSlotId() === SlotId.WL) {
        slot.wildSymbolLifeCount = lifeCounts[position]! as WildSymbolLifeCount;
        slot.changeWildStickyTexture();
      }
    });
  }

  private hideStopSlots() {
    this.slots.forEach((slot) => {
      slot.skip();
    });
  }

  private highLightAnticipationSlots(anticipationReelId: number) {
    const array: number[] = [];
    setNextResult()?.bet.result.spinResult.forEach((icon, index) => {
      const reelId = index % REELS_AMOUNT;
      if (reelId <= anticipationReelId && icon.id === SlotId.SC) {
        array.push(index);
      } else if (reelId > anticipationReelId) {
        array.push(index);
      }
    });
    this.grayOutSlots(array);
  }

  private playReelStopSound(reelId: number, scatterStopCount = 0) {
    let stopSound;

    if (scatterStopCount > 0) {
      if (setGameMode() !== GameMode.FREE_SPINS) {
        if (baseGameScatterStopSoundList[scatterStopCount - 1]!.reelId === reelId) {
          stopSound = baseGameScatterStopSoundList[scatterStopCount - 1]!.sound;
        }
      } else {
        stopSound = freeSpinsScatterStopSoundList[scatterStopCount - 1]!;
      }
    }

    AudioApi.play({
      type: stopSound ?? ISongs.SONG_025_19_SpinStop,
      stopPrev: true,
    });
  }

  private setupAnimationTarget(_reelPositions: number[], scatterStopCount: number[], anticipationReelId: number): void {
    this.anticipationEffectAnimations = [];

    this.anticipationReelId = anticipationReelId;

    for (let reelId = 0; reelId < REELS_AMOUNT; reelId++) {
      for (let slotIndex = 0; slotIndex < SLOTS_PER_REEL_AMOUNT; slotIndex++) {
        const position = slotIndex * REELS_AMOUNT + reelId;
        const slot = this.slots[position]!;
        const slotId = setNextResult()?.bet.result.spinResult[position]!.id!;
        const spinAnimation = this.spinAnimations[position]!;
        const rollingAnimation = spinAnimation.getRolling();
        const endingAnimation = new AnimationChain();

        if (reelId > anticipationReelId) {
          rollingAnimation.duration = 2200;
          const duration = slot.createLoopHoleInOutAnimation(60, 20 + slotIndex * 3, true).duration;
          const anticipationSlotAnimation = slot.createRollingAnimation(60, duration, 100, true);
          const effectAnimation = this.createAnticipationEffectAnimation(
            slotId,
            slot,
            anticipationSlotAnimation.duration,
          );
          anticipationSlotAnimation.addOnStart(() => {
            effectAnimation.start();
          });
          this.anticipationEffectAnimations.push(effectAnimation);
          endingAnimation.appendAnimation(anticipationSlotAnimation);
        } else {
          rollingAnimation.duration = 0;

          const reelLagRolling = slot.createRollingAnimation(45, (this.isTurboSpin ? 100 : 200) * (reelId + 1));
          endingAnimation.appendAnimation(reelLagRolling);

          const slowDownAnimation = slot.createLoopHoleInOutAnimation(this.isTurboSpin ? 45 : 30, 2);
          endingAnimation.appendAnimation(slowDownAnimation);
        }

        // create last stop
        const stopAnimation = slot.createStopAnimation(slotId);
        if (!(reelId > anticipationReelId) && slotIndex === SLOTS_PER_REEL_AMOUNT - 1) {
          stopAnimation.addOnStart(() => {
            this.playReelStopSound(reelId, scatterStopCount[reelId]);
          });
        }
        endingAnimation.appendAnimation(stopAnimation);

        if (slotIndex === SLOTS_PER_REEL_AMOUNT - 1) {
          // all complete stop
          if (reelId === REELS_AMOUNT - 1) {
            endingAnimation.addOnComplete(() => {
              this.resetSlotsTint();
              eventManager.emit(EventTypes.REELS_STOPPED, setIsTurboSpin());
            });
          }

          // highlight reel for anticipation
          if (anticipationReelId !== REELS_AMOUNT) {
            if (reelId === REELS_AMOUNT - 2) {
              endingAnimation.addOnComplete(() => {
                if (SlotMachine.getInstance().state === SlotMachineState.SPIN) {
                  this.highLightAnticipationSlots(anticipationReelId);
                }
              });
            }
          }
        }

        spinAnimation.appendAnimation(endingAnimation);
      }
    }
  }

  private forceStopSlots(): void {
    this.slots.forEach((_slot, position) => {
      const reelId = position % REELS_AMOUNT;
      const spinAnimation = this.spinAnimations[position]!;
      spinAnimation.getStarting().end();
      if (reelId > this.anticipationReelId) {
        spinAnimation.getRolling().duration = 1000;
      } else {
        spinAnimation.getRolling().duration = 0;
      }
      const ending = spinAnimation.getEnding();
      if (ending instanceof AnimationChain) {
        ending.animations.forEach((animation, index) => {
          if (index !== ending.animations.length - 1) {
            animation.duration = 0;
          }
        });
      }
      this.anticipationEffectAnimations &&
        this.anticipationEffectAnimations.forEach((animation) => (animation.duration = 100));
    });
  }

  private onStartHitHammerAnimation(spinResult: SlotId[], paylines: IWinLine[], stickyCounts?: number[]) {
    const winPositions = Array.from(
      new Set(
        paylines.flatMap((p) => {
          return p.winPositions;
        }),
      ),
    );
    const allHammerAnimations: Animation[] = [];
    this.hammerBeatenAnimations = [];

    winPositions.forEach((position) => {
      const reelId = position % REELS_AMOUNT;
      const slotIndex = Math.floor(position / REELS_AMOUNT);
      const hammerAnimation = Tween.createDelayAnimation(reelId * HAMMER_ANIMATION_REEL_DELAY);

      const hammer = new Hammer();
      hammer.y = moleSlotYPositions[slotIndex]! - 70;
      hammer.x = REEL_WIDTH * reelId + REEL_WIDTH / 2 + moleSlotXPositionOffsets[slotIndex]![reelId]!;
      hammer.scale.set(moleSlotScales[slotIndex], moleSlotScales[slotIndex]);
      this.addChild(hammer);

      hammerAnimation.addOnComplete(() => {
        let beatenAnimation;
        if (stickyCounts && stickyCounts.length > 0) {
          beatenAnimation = this.slots[position]!.getHammerBeatenAnimation(
            stickyCounts[position]! as WildSymbolLifeCount,
          );
        } else {
          beatenAnimation = this.slots[position]!.getHammerBeatenAnimation();
        }
        beatenAnimation.start();
        this.hammerBeatenAnimations.push(beatenAnimation);

        !(spinResult[position] === SlotId.SC) && hammer.startHitAnimation();

        const deleteDelay = Tween.createDelayAnimation(HAMMER_ANIMATION_DURATION);
        deleteDelay.addOnComplete(() => {
          this.removeChild(hammer);
        });
        deleteDelay.start();
      });
      allHammerAnimations.push(hammerAnimation);
    });
    this.grayOutSlots(winPositions);
    allHammerAnimations.forEach((animation) => animation.start());
  }

  private skipAnimations() {
    this.winAnimation?.skip();
    this.spinAnimations.forEach((v) => v.skip());
    this.hammerBeatenAnimations.forEach((v) => v.skip());
  }

  private createScatterWinAnimation(payline: IWinLine) {
    const scatterWin = new AnimationGroup();
    payline.winPositions
      .map((position) => {
        return this.slots[position]!.getScatterWinAnimation();
      })
      .forEach((animation) => {
        scatterWin.addAnimation(animation);
      });
    return scatterWin;
  }

  private onStartWinAnimation(initPayLines: IWinLine[], _winAmount: number, stickyCounts?: number[]) {
    const animation = new AnimationChain();

    this.hammerBeatenAnimations.forEach((v) => v.skip());

    // exclude scatters
    const paylines = initPayLines
      .filter((p) => {
        return p.lineId !== null;
      })
      .filter(isNotNullOrUndefined);

    if (paylines.length > 0) {
      const eachHighlightSlots = this.createHighlightChainAnimation(paylines, true, stickyCounts);
      animation.appendAnimation(eachHighlightSlots);
      animation.start();
    }
    this.winAnimation = animation;

    // independent scatter
    const scatterPayline = initPayLines.filter((p) => {
      return p.lineId === null;
    });

    if (scatterPayline.length === 1) {
      this.scatterWinAnimation = this.createScatterWinAnimation(scatterPayline[0]!);
      this.scatterWinAnimation.start();
    }
  }

  private grayOutSlots(highLightPositions: number[]) {
    this.slots.forEach((slot, i) => {
      if (highLightPositions.includes(i)) {
        slot.tint = 0xffffff;
      } else {
        slot.tint = 0x888888;
      }
    });

    if (setScatterPositions().length > 0) {
      setScatterPositions().forEach((position) => {
        this.slots[position]!.tint = 0xffffff;
      });
    }
  }

  private resetSlotsTint() {
    this.slots.forEach((slot) => (slot.tint = 0xffffff));
  }

  private highlightSlots(slotPositions: number[], enableGrayOut = true, stickyCounts?: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    if (enableGrayOut) {
      animationGroup.addOnStart(() => {
        this.grayOutSlots(slotPositions);
      });
    }

    slotPositions.forEach((position) => {
      if (stickyCounts && stickyCounts.length > 0 && stickyCounts[position]! > 0) {
        animationGroup.addAnimation(this.slots[position]!.getPayLineEffectAnimation());
      } else {
        animationGroup.addAnimation(this.slots[position]!.getWinAnimation());
      }
    });

    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean, stickyCounts?: number[]): Animation {
    const animationChain = new AnimationChain({ isLoop });

    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions, true, stickyCounts);
      chain.addOnStart(() => {
        if (this.winAnimation) {
          // excluding scatter
          if (payline.lineId !== null) {
            AudioApi.play({ type: ISongs.SONG_025_18_Piyo });
          }
        }
      });
      chain.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.SONG_025_18_Piyo });
      });
      animationChain.appendAnimation(chain);
    });
    return animationChain;
  }

  private createAnticipationEffectAnimation(slotId: SlotId, slot: MoleSlot, duration: number) {
    const animation = Tween.createDelayAnimation(duration);
    const spine = new StrictSpine('nearmiss');

    animation.addOnStart(() => {
      spine.y = slot.y;
      spine.x = slot.x;
      spine.scale.set(slot.scale.x, slot.scale.y);
      spine.state.setAnimation(0, 'effect_frame_nearmiss', true);
      AudioApi.play({ type: ISongs.SONG_025_14_LongSpin, stopPrev: true });
      this.addChild(spine);
    });

    animation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_025_14_LongSpin });
      AudioApi.play({ type: ISongs.SONG_025_15_LongSpin_End, stopPrev: true });
      if (slotId === SlotId.SC) {
        AudioApi.play({ type: ISongs.SONG_025_13_Scatter_03 });
      }

      const animationName = slotId === SlotId.SC ? 'effect_frame_nearmiss_stop_win' : 'effect_frame_nearmiss_stop';
      spine.state.setAnimation(0, animationName, false);

      const duration = spine.skeleton.data.findAnimation(animationName)!.duration * 1000;
      const deleteDelay = Tween.createDelayAnimation(duration);
      deleteDelay.addOnComplete(() => {
        spine.state.setEmptyAnimation(0, 0);
        this.removeChild(spine);
      });
      deleteDelay.start();
    });

    animation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_025_14_LongSpin });
      AudioApi.stop({ type: ISongs.SONG_025_15_LongSpin_End });
      if (slotId === SlotId.SC) {
        AudioApi.stop({ type: ISongs.SONG_025_13_Scatter_03 });
      }
      spine.state.setEmptyAnimation(0, 0);
      this.removeChild(spine);
    });

    return animation;
  }

  private startChainFluctuationAnimation(positions: number[]) {
    const animationGroup = new AnimationGroup();
    positions.forEach((position) => {
      const slot = this.slots[position]!;
      const spine = new StrictSpine('chain_fluctuation');
      const duration = spine.skeleton.data.findAnimation('chain_fluctuation')!.duration * 1000;
      const animation = Tween.createDelayAnimation(duration);

      animation.addOnStart(() => {
        spine.position = slot.position;
        spine.scale.set(slot.scale.x, slot.scale.y);
        spine.state.setAnimation(0, 'chain_fluctuation', false);
        spine.state.addAnimation(0, 'chain_fluctuation', false, 0);
        spine.state.addAnimation(0, 'chain_fluctuation', false, 0);
        this.addChild(spine);
      });
      animationGroup.addOnSkip(() => {
        this.removeChild(spine);
      });
      animationGroup.addAnimation(animation);
    });
    this.spineAnimation = animationGroup;
    this.spineAnimation.start();
  }

  private onShowChainStopSlots(_spinResult: SlotId[], winPositions: number[]) {
    this.spineAnimation?.skip();

    // no chain symbol
    this.slots.forEach((slot, position) => {
      if (winPositions.length && !winPositions.includes(position) && !setScatterPositions().includes(position)) {
        slot.idle = true;
      }
    });

    if (winPositions.length) {
      this.grayOutSlots(winPositions);
    } else {
      this.resetSlotsTint();
    }
  }

  public createSpinAnimation(isTurboSpin = false) {
    this.isTurboSpin = isTurboSpin;
    this.spinAnimations = [];
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const moleSlot = this.slots[i * REELS_AMOUNT + j]!;
        const starting = moleSlot!.createLoopHoleInOutAnimation(isTurboSpin ? 45 : 30, isTurboSpin ? 1 : 3);
        const rolling = moleSlot!.createRollingAnimation(45);
        moleSlot.idle = false;
        const spinAnimation = new MoleSpinAnimation({
          staringAnimation: starting,
          rollingAnimation: rolling,
        });
        this.spinAnimations.push(spinAnimation);
      }
    }
    return this.spinAnimations;
  }

  public createChainSpinAnimation(spinResult: SlotId[], prevWinPosition: number[], prevLifeCounts: number[]) {
    const spinAnimation = Tween.createDelayAnimation(3000);

    spinAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

      const fluctuationPositions = prevWinPosition.filter((position) => {
        if (prevLifeCounts.length && prevLifeCounts[position]! > 0) {
          return false;
        }
        return true;
      });
      this.startChainFluctuationAnimation(fluctuationPositions);

      eventManager.emit(EventTypes.START_CHAIN_SPIN_ANIMATION);

      const animationGroup = new AnimationGroup();

      let doneSetSpinStopSound = false;
      prevWinPosition.forEach((position) => {
        const slot = this.slots[position]!;
        const slotId = spinResult[position]!;
        if (prevLifeCounts.length && prevLifeCounts[position]! > 0 && slot.getSlotId() === SlotId.WL) {
          slot.wildSymbolLifeCount = prevLifeCounts[position]! as WildSymbolLifeCount;
          slot.changeWildStickyTexture();
          slot.tint = 0xffffff;
        } else {
          slot.wildSymbolLifeCount = 0;
          const slotSpinAnimation = new AnimationChain();
          const starting = slot.createLoopHoleInOutAnimation(setIsTurboSpin() ? 45 : 30, 3);
          const ending = slot.createLoopHoleInOutAnimation(setIsTurboSpin() ? 45 : 30, 2);
          const stop = slot.createStopAnimation(slotId);

          slotSpinAnimation.appendAnimation(starting);
          slotSpinAnimation.appendAnimation(ending);
          slotSpinAnimation.appendAnimation(stop);

          if (!doneSetSpinStopSound) {
            doneSetSpinStopSound = true;
            stop.addOnStart(() => {
              AudioApi.play({
                type: ISongs.SONG_025_19_SpinStop,
                stopPrev: true,
              });
            });
          }

          animationGroup.addAnimation(slotSpinAnimation);
        }
      });

      animationGroup.start();
    });

    return spinAnimation;
  }
}

export default MoleSlotsContainer;
