import { EventTypes } from '../global.d';
import { setIsAutoSpins, setIsBuyFeaturePurchased } from '../gql/cache';
import { eventManager } from '../slotMachine/config';

export const fallBackReelPosition = () => {
  if (setIsBuyFeaturePurchased()) {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUY_FEATURE);
  } else {
    eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    if (setIsAutoSpins()) {
      setIsAutoSpins(false);
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }
};
