import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SONG_025_01_BaseGameBGM_Base: '025_01_BaseGameBGM_Base',
  SONG_025_02_BaseGameBGM_Melo: '025_02_BaseGameBGM_Melo',
  SONG_025_03_FreeSpinBGM: '025_03_FreeSpinBGM',
  SONG_025_04_TotalWinBanner: '025_04_TotalWinBanner',
  SONG_025_05_BigWin_Intro: '025_05_BigWin_Intro',
  SONG_025_06_BigWin_Loop: '025_06_BigWin_Loop',
  SONG_025_07_BigWin_END: '025_07_BigWin_END',
  SONG_025_11_Scatter_01: '025_11_Scatter_01',
  SONG_025_12_Scatter_02: '025_12_Scatter_02',
  SONG_025_13_Scatter_03: '025_13_Scatter_03',
  SONG_025_14_LongSpin: '025_14_LongSpin',
  SONG_025_15_LongSpin_End: '025_15_LongSpin_End',
  SONG_025_16_Chain: '025_16_Chain',
  SONG_025_17_Hammer: '025_17_Hammer',
  SONG_025_18_Piyo: '025_18_Piyo',
  SONG_025_19_SpinStop: '025_19_SpinStop',
  SONG_FeatureTrigger: 'FeatureTrigger',
  SONG_SFX_BuyFeature: 'SFX_BuyFeature',
  SONG_SFX_SM_CountUp_End: 'SFX_SM_CountUp_End',
  SONG_SFX_SM_CountUp_Loop: 'SFX_SM_CountUp_Loop',
  SONG_SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SONG_SFX_UI_BetChange: 'SFX_UI_BetChange',
  SONG_SFX_UI_Close: 'SFX_UI_Close',
  SONG_SFX_UI_General: 'SFX_UI_General',
  SONG_SFX_UI_Hover: 'SFX_UI_Hover',
  SONG_SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SONG_SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  SONG_SFX_UI_SpinStart: 'SFX_UI_SpinStart',
  SONG_SFX_Win_Big: 'SFX_Win_Big',
  SONG_SFX_Win_Epic: 'SFX_Win_Epic',
  SONG_SFX_Win_Great: 'SFX_Win_Great',
  SONG_SFX_Win_Mega: 'SFX_Win_Mega',
  SONG_Yocho_Phoenix: 'Yocho_Phoenix',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  '025_01_BaseGameBGM_Base': [0, 67133.17460317462, true],
  '025_02_BaseGameBGM_Melo': [69000, 67132.97052154195, true],
  '025_03_FreeSpinBGM': [138000, 80000, true],
  '025_04_TotalWinBanner': [219000, 4013.333333333321],
  '025_05_BigWin_Intro': [225000, 3500],
  '025_06_BigWin_Loop': [230000, 38000, true],
  '025_07_BigWin_END': [269000, 8000],
  '025_11_Scatter_01': [278000, 924.0136054421555],
  '025_12_Scatter_02': [280000, 921.337868480748],
  '025_13_Scatter_03': [282000, 915.9863945578195],
  '025_14_LongSpin': [284000, 3205.351473922917],
  '025_15_LongSpin_End': [289000, 1449.3197278911794],
  '025_16_Chain': [292000, 1004.0136054421964],
  '025_17_Hammer': [295000, 179.3197278911407],
  '025_18_Piyo': [297000, 365.21541950111214],
  '025_19_SpinStop': [299000, 235.55555555554974],
  'FeatureTrigger': [301000, 879.841269841279],
  'SFX_BuyFeature': [303000, 666.6666666666856],
  'SFX_SM_CountUp_End': [305000, 933.4693877551103],
  'SFX_SM_CountUp_Loop': [307000, 399.7505668934309, true],
  'SFX_UI_AutoSpin': [309000, 320.47619047619946],
  'SFX_UI_BetChange': [311000, 32.08616780045759],
  'SFX_UI_Close': [313000, 200.68027210885475],
  'SFX_UI_General': [315000, 88.34467120180989],
  'SFX_UI_Hover': [317000, 151.76870748297233],
  'SFX_UI_MaxBet': [319000, 122.92517006801518],
  'SFX_UI_MenuOpen': [321000, 277.1428571428487],
  'SFX_UI_SpinStart': [323000, 249.59183673468033],
  'SFX_Win_Big': [325000, 1641.3378684807185],
  'SFX_Win_Epic': [328000, 3124.013605442201],
  'SFX_Win_Great': [333000, 2566.666666666663],
  'SFX_Win_Mega': [337000, 2134.671201814058],
  'Yocho_Phoenix': [341000, 4033.33333333336],
};
