import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { EventTypes } from '../../global.d';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_HEIGHT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  eventManager,
} from '../config';
import { Combos, Icon } from '../d';
import MoleSlot from '../mole/moleSlot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: CallableFunction;

  private payTableContainers: PIXI.Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(icons: Icon[], getSlotByIdFn: CallableFunction) {
    super();
    this.icons = icons;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    this.visible = true;
    eventManager.addListener(EventTypes.DISABLE_PAY_TABLE, (isVisible: boolean) => (this.visible = isVisible));
    this.payTableContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payTableData.find((d) => d.slug === slug)?.combos as Combos,
    );
    this.createContainers();
  }

  private createContainers() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot = this.getSlotById(index) as MoleSlot;
        const icon = this.icons.find((icon) => icon.id === slot.getSlotId())!;
        const container = new PIXI.Container();
        container.x = slot.x;
        container.y = slot.y;
        container.scale = slot.scale;
        container.zIndex = 1;
        container.interactive = true;
        const rect = new PIXI.Graphics();
        rect.pivot.set(REEL_WIDTH / 2, REEL_HEIGHT / 2);
        rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
        rect.drawRect(0, 0, REEL_WIDTH, REEL_HEIGHT);
        rect.alpha = 0.0;
        container.addChild(rect);

        container.on('mousedown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        container.on('touchstart', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });

        const payTable = new MiniPayTable(index, icon, this.combosMap[icon.id]);
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  public setSpinResult(spinResult: Icon[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index]!;
      const payTable = container!.children[1] as MiniPayTable;
      payTable.setPayTableData(icon.id, this.combosMap[icon.id]);
    });
  }
}

export default MiniPayTableContainer;
