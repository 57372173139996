import * as PIXI from 'pixi.js';

import { APPLICATION_FPS } from '../config';

import Animation from './animation';
import { IAnimation } from './d';

class SpriteAnimation extends Animation {
  public spriteAnimation: PIXI.AnimatedSprite;

  constructor(options: IAnimation, textures: PIXI.Texture[], fps = APPLICATION_FPS) {
    super(options);
    this.spriteAnimation = new PIXI.AnimatedSprite(textures);
    this.spriteAnimation.loop = true;
    this.spriteAnimation.animationSpeed = fps / APPLICATION_FPS;

    const msec = 1000 / fps;
    this.duration = this.spriteAnimation.textures.length * msec;

    if (!options.isLoop) {
      this.spriteAnimation.onLoop = this.onComplete.bind(this);
      this.addOnComplete(() => {
        this.spriteAnimation.stop();
      });
    }
    this.spriteAnimation.onComplete = this.onComplete.bind(this);
  }

  public override start(): void {
    super.start();
    this.spriteAnimation.play();
  }

  public override skip(): void {
    super.skip();
    this.spriteAnimation.stop();
  }
}

export default SpriteAnimation;
