import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/viewContainer';
import {
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE,
  BIG_WIN_TITLE_SCALE,
  BIG_WIN_ZOOM_TITLE_DURATION,
  EPIC_WIN_TITLE,
  EPIC_WIN_TITLE_SCALE,
  EPIC_WIN_ZOOM_TITLE_DURATION,
  GREAT_WIN_TITLE,
  GREAT_WIN_TITLE_SCALE,
  GREAT_WIN_ZOOM_TITLE_DURATION,
  MEGA_WIN_TITLE,
  MEGA_WIN_TITLE_SCALE,
  MEGA_WIN_ZOOM_TITLE_DURATION,
  WinStages,
  bigWinStyles,
  epicWinStyles,
  eventManager,
  greatWinStyles,
  megaWinStyles,
} from '../config';

import { WIN_LABEL_X, WIN_LABEL_Y } from './config';

class WinLabelContainer extends ViewContainer {
  public bigWinLabel: PIXI.Text;

  public megaWinLabel: PIXI.Text;

  public greatWinLabel: PIXI.Text;

  public epicWinLabel: PIXI.Text;

  private bigWinAnimation: Animation | null = null;

  private megaWinAnimation: Animation | null = null;

  private greatWinAnimation: Animation | null = null;

  private epicWinAnimation: Animation | null = null;

  private bigWinFadeOutAnimation: Animation | null = null;

  private megaWinFadeOutAnimation: Animation | null = null;

  private greatWinFadeOutAnimation: Animation | null = null;

  private epicWinFadeOutAnimation: Animation | null = null;

  constructor() {
    super();
    this.zIndex = 5;
    this.bigWinLabel = this.initBigWin();
    this.megaWinLabel = this.initMegaWin();
    this.greatWinLabel = this.initGreatWin();
    this.epicWinLabel = this.initEpicWin();
    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());
  }

  private initBigWin(): PIXI.Text {
    const bigWin = new PIXI.Text(BIG_WIN_TITLE, bigWinStyles);
    bigWin.anchor.set(0.5, 0.5);
    bigWin.position.set(WIN_LABEL_X, WIN_LABEL_Y - bigWin.height);
    bigWin.visible = false;
    this.addChild(bigWin);
    return bigWin;
  }

  private initMegaWin(): PIXI.Text {
    const megaWin = new PIXI.Text(MEGA_WIN_TITLE, megaWinStyles);
    megaWin.anchor.set(0.5, 0.5);
    megaWin.position.set(WIN_LABEL_X, WIN_LABEL_Y - megaWin.height);
    megaWin.visible = false;
    this.addChild(megaWin);
    return megaWin;
  }

  private initGreatWin(): PIXI.Text {
    const greatWin = new PIXI.Text(GREAT_WIN_TITLE, greatWinStyles);
    greatWin.anchor.set(0.5, 0.5);
    greatWin.position.set(WIN_LABEL_X, WIN_LABEL_Y - greatWin.height);
    greatWin.visible = false;
    this.addChild(greatWin);
    return greatWin;
  }

  private initEpicWin(): PIXI.Text {
    const epicWin = new PIXI.Text(EPIC_WIN_TITLE, epicWinStyles);
    epicWin.anchor.set(0.5, 0.5);
    epicWin.position.set(WIN_LABEL_X, WIN_LABEL_Y - epicWin.height);
    epicWin.visible = false;
    this.addChild(epicWin);
    return epicWin;
  }

  private setBigWinVisibility(visible: boolean) {
    this.bigWinLabel.visible = visible;
    const initScale = this.bigWinLabel.scale.x;
    if (visible) {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = createZoomAnimation(
        this.bigWinLabel,
        BIG_WIN_TITLE_SCALE * initScale,
        BIG_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.bigWinAnimation.addOnSkip(() => {
        this.bigWinLabel.scale.set(initScale);
      });
      this.bigWinAnimation.start();
    } else {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = null;
    }
  }

  private setMegaWinVisibility(visible: boolean) {
    this.megaWinLabel.visible = visible;
    const initScale = this.megaWinLabel.scale.x;
    if (visible) {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = createZoomAnimation(
        this.megaWinLabel,
        MEGA_WIN_TITLE_SCALE * initScale,
        MEGA_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.megaWinAnimation.addOnSkip(() => {
        this.megaWinLabel.scale.set(initScale);
      });
      this.megaWinAnimation.start();
    } else {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = null;
    }
  }

  private setGreatWinVisibility(visible: boolean) {
    this.greatWinLabel.visible = visible;
    const initScale = this.greatWinLabel.scale.x;
    if (visible) {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = createZoomAnimation(
        this.greatWinLabel,
        GREAT_WIN_TITLE_SCALE * initScale,
        GREAT_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.greatWinAnimation.addOnSkip(() => {
        this.greatWinLabel.scale.set(initScale);
      });
      this.greatWinAnimation.start();
    } else {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = null;
    }
  }

  private setEpicWinVisibility(visible: boolean) {
    this.epicWinLabel.visible = visible;
    const initScale = this.epicWinLabel.scale.x;
    if (visible) {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = createZoomAnimation(
        this.epicWinLabel,
        EPIC_WIN_TITLE_SCALE * initScale,
        EPIC_WIN_ZOOM_TITLE_DURATION,
        false,
        initScale,
      );
      this.epicWinAnimation.addOnSkip(() => {
        this.epicWinLabel.scale.set(initScale);
      });
      this.epicWinAnimation.start();
    } else {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = null;
    }
  }

  private skipAllWinAnimations() {
    this.setBigWinVisibility(false);
    this.setMegaWinVisibility(false);
    this.setGreatWinVisibility(false);
    this.setEpicWinVisibility(false);
  }

  private skipFadeAnimation() {
    this.bigWinFadeOutAnimation?.skip();
    this.megaWinFadeOutAnimation?.skip();
    this.greatWinFadeOutAnimation?.skip();
    this.epicWinFadeOutAnimation?.skip();
  }

  private startFade(stage: WinStages) {
    if (stage === WinStages.BigWin) {
      this.bigWinFadeOutAnimation = this.createFadeAnimation(this.bigWinLabel);
      const onEnd = () => {
        this.bigWinLabel.visible = false;
        this.bigWinLabel.alpha = 1;
        this.bigWinFadeOutAnimation = null;
      };
      this.bigWinFadeOutAnimation.addOnSkip(onEnd);
      this.bigWinFadeOutAnimation.addOnComplete(onEnd);
      this.bigWinFadeOutAnimation.start();
    }

    if (stage === WinStages.MegaWin) {
      this.megaWinFadeOutAnimation = this.createFadeAnimation(this.megaWinLabel);
      const onEnd = () => {
        this.megaWinLabel.visible = false;
        this.megaWinLabel.alpha = 1;
        this.megaWinFadeOutAnimation = null;
      };
      this.megaWinFadeOutAnimation.addOnSkip(onEnd);
      this.megaWinFadeOutAnimation.addOnComplete(onEnd);
      this.megaWinFadeOutAnimation.start();
    }

    if (stage === WinStages.GreatWin) {
      this.greatWinFadeOutAnimation = this.createFadeAnimation(this.greatWinLabel);
      const onEnd = () => {
        this.greatWinLabel.visible = false;
        this.greatWinLabel.alpha = 1;
        this.greatWinFadeOutAnimation = null;
      };
      this.greatWinFadeOutAnimation.addOnSkip(onEnd);
      this.greatWinFadeOutAnimation.addOnComplete(onEnd);
      this.greatWinFadeOutAnimation.start();
    }
    if (stage === WinStages.EpicWin) {
      this.epicWinFadeOutAnimation = this.createFadeAnimation(this.epicWinLabel);
      const onEnd = () => {
        this.epicWinLabel.visible = false;
        this.epicWinLabel.alpha = 1;
        this.epicWinFadeOutAnimation = null;
      };
      this.epicWinFadeOutAnimation.addOnSkip(onEnd);
      this.epicWinFadeOutAnimation.addOnComplete(onEnd);
      this.epicWinFadeOutAnimation.start();
    }
  }

  private createFadeAnimation(target: PIXI.Text) {
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: target,
      property: TweenProperties.ALPHA,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      duration: BIG_WIN_END_DURATION,
    });
    fadeOutAnimation.addOnComplete(() => {
      target.visible = false;
      target.alpha = 1;
    });
    return fadeOutAnimation;
  }
}

export default WinLabelContainer;
