import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';
import { SpineInterface, mobileVersionSpineFile, spineFile } from './spine.generated';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.SC]: ResourceTypes.symbolSc,
};

export type WildSymbolLifeCount = 0 | 1 | 2 | 3;

export const MAPPED_FREE_SPINS_WILD_SYMBOLS: Record<WildSymbolLifeCount, ResourceTypes> = {
  [3]: ResourceTypes.symbolWlL3,
  [2]: ResourceTypes.symbolWlL2,
  [1]: ResourceTypes.symbolWl,
  [0]: ResourceTypes.symbolWl,
};

export type SymbolAllAnimationNames = SpineInterface['symbol_all']['animations'];

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    stop?: SymbolAllAnimationNames;
    hammerHit?: SymbolAllAnimationNames;
    win?: SymbolAllAnimationNames;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_a',
    hammerHit: 'symbol_win1_a',
    win: 'symbol_win2_a',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_b',
    hammerHit: 'symbol_win1_b',
    win: 'symbol_win2_b',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_c',
    hammerHit: 'symbol_win1_c',
    win: 'symbol_win2_c',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_d',
    hammerHit: 'symbol_win1_d',
    win: 'symbol_win2_d',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_e',
    hammerHit: 'symbol_win1_e',
    win: 'symbol_win2_e',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_f',
    hammerHit: 'symbol_win1_f',
    win: 'symbol_win2_f',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_g',
    hammerHit: 'symbol_win1_g',
    win: 'symbol_win2_g',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_h',
    hammerHit: 'symbol_win1_h',
    win: 'symbol_win2_h',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_i',
    hammerHit: 'symbol_win1_i',
    win: 'symbol_win2_i',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_wild',
    hammerHit: 'symbol_win1_wild',
    win: 'symbol_win2_wild',
  },
  [SlotId.SC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    stop: 'symbol_stop_sc',
    hammerHit: 'symbol_win1_sc',
    win: 'symbol_win2_sc',
  },
};

export const excerptWinBackSymbols = [SlotId.SC, SlotId.WL];

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.basegameBg1,
  ResourceTypes.basegameBg2,
  ResourceTypes.freegameBg1,
  ResourceTypes.freegameBg2,
  ResourceTypes.messagebannerA,
  ResourceTypes.messagebannerB,
  ResourceTypes.chain,
  ResourceTypes.logo,
  ResourceTypes.sparks,
  ResourceTypes.particle,
  ResourceTypes.lineLarge,
  ResourceTypes.lineMedium,
  ResourceTypes.lineShort,
  ResourceTypes.holeB,
  ResourceTypes.holeF,
  ResourceTypes.symbolA,
  ResourceTypes.symbolABlack,
  ResourceTypes.symbolABlur,
  ResourceTypes.symbolABlur2,
  ResourceTypes.symbolB,
  ResourceTypes.symbolBBlack,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolBBlur2,
  ResourceTypes.symbolC,
  ResourceTypes.symbolCBlack,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolCBlur2,
  ResourceTypes.symbolD,
  ResourceTypes.symbolDBlack,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolDBlur2,
  ResourceTypes.symbolE,
  ResourceTypes.symbolEBlack,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolEBlur2,
  ResourceTypes.symbolF,
  ResourceTypes.symbolFBlack,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolFBlur2,
  ResourceTypes.symbolG,
  ResourceTypes.symbolGBlack,
  ResourceTypes.symbolGBlur,
  ResourceTypes.symbolGBlur2,
  ResourceTypes.symbolH,
  ResourceTypes.symbolHBlack,
  ResourceTypes.symbolHBlur,
  ResourceTypes.symbolHBlur2,
  ResourceTypes.symbolHole,
  ResourceTypes.symbolI,
  ResourceTypes.symbolIBlack,
  ResourceTypes.symbolIBlur,
  ResourceTypes.symbolIBlur2,
  ResourceTypes.symbolSc,
  ResourceTypes.symbolScBlack,
  ResourceTypes.symbolScBlur,
  ResourceTypes.symbolScBlur2,
  ResourceTypes.symbolWl,
  ResourceTypes.symbolWlBlack,
  ResourceTypes.symbolWlBlur,
  ResourceTypes.symbolWlBlur2,
  ResourceTypes.symbolWlL2,
  ResourceTypes.symbolWlL2Black,
  ResourceTypes.symbolWlL2Blur,
  ResourceTypes.symbolWlL2Blur2,
  ResourceTypes.symbolWlL3,
  ResourceTypes.symbolWlL3Black,
  ResourceTypes.symbolWlL3Blur,
  ResourceTypes.symbolWlL3Blur2,
  ResourceTypes.titlelogo,
  ResourceTypes.backdrop,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureCancelBtnDisable,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtnDisable,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureOkBtnDisable,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeaturePlusBtnDisable,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnNotActive,
  ResourceTypes.buttonCancel,
  ResourceTypes.buttonHistory,
  ResourceTypes.buttonInfo,
  ResourceTypes.buttonLobby,
  ResourceTypes.buttonOk,
  ResourceTypes.buttonSetting,
  ResourceTypes.infoBtnAutoplayStop,
  ResourceTypes.infoBtnAutoplay,
  ResourceTypes.infoBtnBetSettings,
  ResourceTypes.infoBtnInfo,
  ResourceTypes.infoBtnMenu,
  ResourceTypes.infoBtnSound,
  ResourceTypes.infoBtnSpin,
  ResourceTypes.infoBtnStop,
  ResourceTypes.infoBtnTurbo,
  ResourceTypes.infoPaylines,
  ResourceTypes.intro01,
  ResourceTypes.intro02,
  ResourceTypes.intro03,
  ResourceTypes.introBg,
  ResourceTypes.textLogo,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    ...spineFile.map((name) => ({
      name,
      url: generateTexturePath(
        '/animations',
        `${name}/${name}.json`,
        isMobile && mobileVersionSpineFile.includes(name),
      ),
    })),
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'coinsAnimation',
    url: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
