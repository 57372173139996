export const SCENE_CHANGE_BACKGROUND_COLOR = 0x000000;
export const SCENE_CHANGE_FADE_TIME = 1000;

export const sceneChangeFreeSpin = {
  fadeIn: { duration: 1000, beginValue: 0, targetValue: 1 },
  fadeOut: { duration: 1000, beginValue: 1, targetValue: 0 },
};

export const sceneChangeBaseSpin = {
  fadeIn: { duration: 1000, beginValue: 0, targetValue: 1 },
  fadeOut: { duration: 1000, beginValue: 1, targetValue: 0 },
};
