import { Container, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { GAME_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT, eventManager } from '../config';

import {
  CHAIN_TEXT_ANIMATION_IN_DURATION,
  CHAIN_TEXT_ANIMATION_IN_END_SCALE,
  CHAIN_TEXT_ANIMATION_IN_START_RADIAN,
  CHAIN_TEXT_ANIMATION_IN_START_SCALE,
  CHAIN_TEXT_ANIMATION_OUT_DURATION,
  CHAIN_TEXT_ANIMATION_SHOW_WAITING_DURATION,
} from './config';

export class ChainTextSprite extends Container {
  private sprite = new Sprite(Texture.from(ResourceTypes.chain));

  constructor() {
    super();
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.x = GAME_CONTAINER_WIDTH / 2;
    this.sprite.y = SLOTS_CONTAINER_HEIGHT / 2 - 150;
    this.sprite.visible = false;
    this.addChild(this.sprite);

    eventManager.addListener(EventTypes.START_CHAIN_SPIN_ANIMATION, () => this.startAnimation());
  }

  private createAnimation() {
    const animationChain = new AnimationChain();

    const inAnimationGroup = new AnimationGroup();
    const inDuration = CHAIN_TEXT_ANIMATION_IN_DURATION;
    {
      const fadeIn = new Tween({
        object: this.sprite,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 0,
        target: 1,
        duration: inDuration,
      });

      const startScale = CHAIN_TEXT_ANIMATION_IN_START_SCALE;
      const targetScale = CHAIN_TEXT_ANIMATION_IN_END_SCALE;
      const zoomInX = new Tween({
        object: this.sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: startScale,
        target: targetScale,
        duration: inDuration,
      });
      const zoomInY = new Tween({
        object: this.sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: startScale,
        target: targetScale,
        duration: inDuration,
      });

      const startAngle = CHAIN_TEXT_ANIMATION_IN_START_RADIAN;
      const rotation = new Tween({
        object: this.sprite,
        property: TweenProperties.ROTATION,
        propertyBeginValue: startAngle,
        target: 0,
        duration: inDuration,
      });

      inAnimationGroup.addAnimation(zoomInX);
      inAnimationGroup.addAnimation(zoomInY);
      inAnimationGroup.addAnimation(fadeIn);
      inAnimationGroup.addAnimation(rotation);
      animationChain.appendAnimation(inAnimationGroup);
    }

    {
      const showWaitingDuration = CHAIN_TEXT_ANIMATION_SHOW_WAITING_DURATION;
      animationChain.appendAnimation(Tween.createDelayAnimation(showWaitingDuration));
    }

    {
      const duration = CHAIN_TEXT_ANIMATION_OUT_DURATION;
      const fadeOut = new Tween({
        object: this.sprite,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 1,
        target: 0,
        duration: duration,
      });
      animationChain.appendAnimation(fadeOut);
    }

    animationChain.addOnStart(() => {
      this.sprite.visible = true;
      AudioApi.play({ type: ISongs.SONG_025_16_Chain });
    });

    animationChain.addOnComplete(() => {
      this.sprite.visible = false;
    });

    return animationChain;
  }

  private startAnimation() {
    this.createAnimation().start();
  }
}
