import * as PIXI from 'pixi.js';

import AnimationChain from './animationChain';
import AnimationGroup from './animationGroup';
import { TweenProperties } from './d';
import Tween from './tween';

export const createZoomAnimation = (
  object: PIXI.Text,
  scale: number,
  duration: number,
  isLoop: boolean,
  defaultScale = 1,
  scaleInit?: number,
): AnimationChain => {
  const animationChain = new AnimationChain({ isLoop });
  const zoomInAnimationGroup = new AnimationGroup({});
  const zoomOutAnimationGroup = new AnimationGroup({});
  const zoomInX = new Tween({
    object: object.scale,
    target: scale,
    property: TweenProperties.X,
    propertyBeginValue: scaleInit || defaultScale,
    duration: duration / 2,
  });
  const zoomInY = new Tween({
    object: object.scale,
    target: scale,
    property: TweenProperties.Y,
    propertyBeginValue: scaleInit || defaultScale,
    duration: duration / 2,
  });

  zoomInAnimationGroup.addAnimation(zoomInX);
  zoomInAnimationGroup.addAnimation(zoomInY);

  const zoomOutX = new Tween({
    object: object.scale,
    target: scaleInit || defaultScale,
    property: TweenProperties.X,
    propertyBeginValue: scale,
    duration: duration / 2,
  });

  const zoomOutY = new Tween({
    object: object.scale,
    target: scaleInit || defaultScale,
    property: TweenProperties.Y,
    propertyBeginValue: scale,
    duration: duration / 2,
  });

  zoomOutAnimationGroup.addAnimation(zoomOutX);
  zoomOutAnimationGroup.addAnimation(zoomOutY);
  animationChain.appendAnimation(zoomInAnimationGroup);
  animationChain.appendAnimation(zoomOutAnimationGroup);

  return animationChain;
};
