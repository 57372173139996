import { Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';

export type BackGroundSkin = 'base' | 'freeSpin';

class Background extends ViewContainer {
  private sprite: Sprite;

  constructor() {
    super();
    this.sortableChildren = true;

    const tex = new Sprite(Texture.from(ResourceTypes.basegameBg1));
    tex.anchor.set(0.5, 0.5);
    this.addChild(tex);
    this.sprite = tex;

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (settings.mode === GameMode.FREE_SPINS) {
      this.sprite.texture = Texture.from(ResourceTypes.freegameBg1);
    } else {
      this.sprite.texture = Texture.from(ResourceTypes.basegameBg1);
    }
  }

  override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 2000, height: 2000 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / size.height;
    } else {
      scale = width / size.width;
    }
    if (width > height) {
      this.sprite.y = -40;
    } else {
      this.sprite.y = 0;
    }
    this.sprite.scale.set(scale);
  }
}
export default Background;
