const hitEffectAnimationNameType = {
  EFFECT_A: 'hit_effect_a',
  EFFECT_B: 'hit_effect_b',
  EFFECT_C: 'hit_effect_c',
  EFFECT_D: 'hit_effect_d',
  EFFECT_E: 'hit_effect_e',
  EFFECT_F: 'hit_effect_f',
} as const;

export const hitEffectAnimationNames = Object.values(hitEffectAnimationNameType);
