import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCurrency, setIsDuringBigWinLoop, setIsDuringWinCountUpAnimation } from '../../gql';
import { countStage, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE_SCALE,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  EPIC_WIN_TITLE_SCALE,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_TITLE_SCALE,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_TITLE_SCALE,
  MINIMUM_FRACTION_DIGITS,
  WinStages,
  eventManager,
  winValueStyles,
} from '../config';
import { IWinLine } from '../d';

import { BIG_WIN_JINGLE_DURATION, WIN_TITLE_X, WIN_TITLE_Y } from './config';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winValueText = new PIXI.Text(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    winValueStyles,
  );

  constructor() {
    super();
    this.winValueText.y = WIN_TITLE_Y;
    this.winValueText.x = WIN_TITLE_X;
    this.winValueText.anchor.set(0.5, 0.5);
    this.winValueText.visible = false;

    this.addChild(this.winValueText);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));
  }

  private hideWinCountUpMessage(): void {
    this.winValueText.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(_: IWinLine[], winAmount: number): void {
    this.createWinAnimation(winAmount, () => {
      eventManager.emit(EventTypes.COUNT_UP_END);
    }).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  private createWinAnimation(winCoinAmount: number, countUpEnd: () => void): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(winCoinAmount);
    //const stage = countStage(betAmount, winCoinAmount);
    const stage = countStage(betAmount, winAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });

    setIsDuringWinCountUpAnimation(true);

    if (stage <= WinStages.BaseWin) {
      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      baseWinAnimation.addOnStart(() => {
        AudioApi.play({ type: ISongs.SONG_SFX_SM_CountUp_Loop, stopPrev: true });
      });
      baseWinAnimation.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.SONG_SFX_SM_CountUp_Loop });
        AudioApi.play({ type: ISongs.SONG_SFX_SM_CountUp_End, stopPrev: true });
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        AudioApi.stop({ type: ISongs.SONG_SFX_SM_CountUp_Loop });
        AudioApi.play({ type: ISongs.SONG_SFX_SM_CountUp_End, stopPrev: true });
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      animationChain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_COINS);
      });
      animationChain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });

      setIsDuringBigWinLoop(true);
      const bigWinAnimation = this.createBigWinAnimation(winAmount, betAmount, stage);
      animationChain.appendAnimation(bigWinAnimation);

      if (stage >= WinStages.MegaWin) {
        const megaWinAnimation = this.createMegaWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(megaWinAnimation);
      }
      if (stage >= WinStages.GreatWin) {
        const greatWinAnimation = this.createGreatWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(greatWinAnimation);
      }
      if (stage >= WinStages.EpicWin) {
        const epicWinAnimation = this.createEpicWinAnimation(winAmount, betAmount);
        animationChain.appendAnimation(epicWinAnimation);
      }

      AudioApi.playAsync({ type: ISongs.SONG_025_05_BigWin_Intro, stopPrev: true }).then(() => {
        if (setIsDuringWinCountUpAnimation() && !AudioApi.isPlaying(ISongs.SONG_025_07_BigWin_END)) {
          AudioApi.play({ type: ISongs.SONG_025_06_BigWin_Loop, stopPrev: true });
        }
      });

      const playBigWinEndJingle = Tween.createDelayAnimation(BIG_WIN_JINGLE_DURATION);

      playBigWinEndJingle.addOnStart(() => {
        AudioApi.stop({ type: ISongs.SONG_025_05_BigWin_Intro });
        AudioApi.stop({ type: ISongs.SONG_025_06_BigWin_Loop });
        AudioApi.play({ type: ISongs.SONG_025_07_BigWin_END });
      });
      animationChain.appendAnimation(playBigWinEndJingle);
    }

    // fadeOut winValueText
    {
      const fadeOutAnimation = new Tween({
        propertyBeginValue: 1,
        target: 0,
        object: this.winValueText,
        // eslint-disable-next-line no-restricted-properties
        easing: (n) => Math.pow(n, 8),
        property: TweenProperties.ALPHA,
        duration: BIG_WIN_END_DURATION,
      });

      fadeOutAnimation.addOnStart(() => {
        eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
        countUpEnd();
      });
      fadeOutAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
        this.clean();
      });
      animationChain.appendAnimation(fadeOutAnimation);
    }

    // all stages win common
    {
      animationChain.addOnStart(() => {
        this.winValueText.alpha = 1;
        this.winValueText.visible = true;
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
        eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
        this.clean();
      });
      animationChain.addOnSkip(() => {
        this.clean();
        eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      });
    }

    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private clean(): void {
    setIsDuringWinCountUpAnimation(false);
    setIsDuringBigWinLoop(false);
    AudioApi.stop({ type: ISongs.SONG_025_05_BigWin_Intro });
    AudioApi.stop({ type: ISongs.SONG_025_06_BigWin_Loop });
    AudioApi.stop({ type: ISongs.SONG_025_07_BigWin_END });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Epic });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Great });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Mega });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Big });
    BgmControl.fadeInBase(3000);

    this.winValueText.visible = false;
    this.winValueText.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createBigWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const bigWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    bigWinAnimation.addOnStart(() => {
      BgmControl.fadeOutAll(1000);

      AudioApi.play({ type: ISongs.SONG_SFX_Win_Big, stopPrev: true });
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, true);
    });
    bigWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Big });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    bigWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Big });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 820;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    bigWinAnimation.appendAnimation(countUpAnimationGroup);
    return bigWinAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const megaWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    megaWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_SFX_Win_Mega, stopPrev: true });
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, true);
    });
    megaWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Mega });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });
    megaWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Mega });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });

    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    megaWinAnimation.appendAnimation(countUpAnimationGroup);
    return megaWinAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const greatWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    greatWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_SFX_Win_Great, stopPrev: true });
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, true);
    });
    greatWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Great });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    greatWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Great });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    greatWinAnimation.appendAnimation(countUpAnimationGroup);
    return greatWinAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number): Animation {
    const epicWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    epicWinAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, true);
      AudioApi.play({ type: ISongs.SONG_SFX_Win_Epic });
    });
    epicWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Epic });
    });
    epicWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_SFX_Win_Epic });
      this.setWinValue(win);
    });
    const duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    epicWinAnimation.appendAnimation(countUpAnimationGroup);

    return epicWinAnimation;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winValueText.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }
}

export default WinCountUpMessage;
