import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/viewContainer';
import {
  GAME_TITLE_LOGO_LANDSCAPE_X_POS,
  GAME_TITLE_LOGO_LANDSCAPE_Y_POS,
  GAME_TITLE_LOGO_PORTRAIT_X_POS,
  GAME_TITLE_LOGO_PORTRAIT_Y_POS,
  eventManager,
} from '../config';

class GameTitleLogo extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  constructor() {
    super();

    this.initGameLogo();
    this.addChild(this.titleLogo);

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0.5);
  }

  override resize(width: number, height: number): void {
    if (width >= height) {
      this.titleLogo.x = GAME_TITLE_LOGO_LANDSCAPE_X_POS;
      this.titleLogo.y = GAME_TITLE_LOGO_LANDSCAPE_Y_POS;
    } else {
      this.titleLogo.x = GAME_TITLE_LOGO_PORTRAIT_X_POS;
      this.titleLogo.y = GAME_TITLE_LOGO_PORTRAIT_Y_POS;
    }
  }

  override onModeChange(settings: { mode: GameMode }): void {
    this.visible = settings.mode === GameMode.FREE_SPINS ? false : true;
  }
}

export default GameTitleLogo;
