import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IAuthInput } from '../../global';
import { configGql, setProgress } from '../../gql';
import { IConfig } from '../../gql/d';
import { parseQuery } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { i18n } = useTranslation();
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status === 100 && <GameScreen />}
    </>
  );
};

export default App;
