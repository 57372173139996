import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { getRandomNumber } from '../../utils';
import { StrictSpine } from '../components/spine/spine';

import { hitEffectAnimationNames } from './config';

export class Hammer extends Container {
  private hitEffect: StrictSpine<'hit_effect'>;
  private body: StrictSpine<'hammer'>;
  private coin: StrictSpine<'coin_few'>;

  constructor() {
    super();
    this.hitEffect = new StrictSpine('hit_effect');
    this.body = new StrictSpine('hammer');
    this.coin = new StrictSpine('coin_few');

    this.addChild(this.hitEffect);
    this.addChild(this.body);
    this.addChild(this.coin);
  }

  public startHitAnimation() {
    this.visible = true;

    const animationNumber = getRandomNumber(hitEffectAnimationNames.length - 1);
    this.hitEffect.state.setAnimation(0, hitEffectAnimationNames[animationNumber]!, false);
    this.hitEffect.state.addEmptyAnimation(0, 0, 0);

    this.body.state.setAnimation(0, 'hammer', false);
    this.body.state.addEmptyAnimation(0, 0, 0);

    this.coin.state.setAnimation(0, 'coin_few', false);
    this.coin.state.addEmptyAnimation(0, 0, 0);

    AudioApi.play({ type: ISongs.SONG_025_17_Hammer, stopPrev: true });
  }
}
