import 'pixi-spine';
import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode, MessageFreeSpinsBannerProps, MessageWinBannerProps } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql';
import { isRegularMode, updateCoinValueAfterBonuses } from '../../utils';
import Tween from '../animations/tween';
import Foreground from '../background/foreground';
import BgmControl from '../bgmControl/bgmControl';
import { ChainTextSprite } from '../chainTextSprite/chainTextSprite';
import { ViewContainer } from '../components/viewContainer';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { FreeSpinsCounter } from '../freeSpins/freeSpinsCounter';
import { BaseMessageBanner } from '../messageBanner/baseMessageBanner';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameTitleLogo from './gameTitleLogo';

class GameView extends ViewContainer {
  public linesContainer: Container;

  public leftLinesContainer!: Container;

  public rightLinesContainer!: Container;

  public coinsAnimationContainer: CoinsAnimationContainer;

  public slotsContainer: Container;

  public slotsDisplayContainer: Container;

  public gameTitleLogo: GameTitleLogo;

  public miniPayTableContainer: Container;

  public winLabelContainer: Container;

  public winCountUpMessage: Container;

  private messageBanner: BaseMessageBanner | null = null;

  private windowSize = { width: 0, height: 0 };

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;

    this.slotsContainer = new Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;

    this.slotsDisplayContainer = props.slotsDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.linesContainer = props.linesContainer;
    this.winLabelContainer = props.winLabelContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    this.slotsContainer.addChild(this.slotsDisplayContainer);

    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.gameTitleLogo = new GameTitleLogo();

    this.addChild(this.gameTitleLogo);
    this.addChild(this.slotsContainer);
    this.addChild(new Foreground());
    this.addChild(new FreeSpinsCounter());
    this.addChild(new ChainTextSprite());
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    const messageFreeSpinsBanner = new MessageFreeSpinsBanner(props).init();

    this.messageBanner = messageFreeSpinsBanner;
    this.scaleBanner();

    this.addChild(messageFreeSpinsBanner);
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_025_04_TotalWinBanner].duration);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(0);
    });
    totalWinDelay.addOnSkip(() => {
      AudioApi.fadeOut(1000, ISongs.SONG_025_04_TotalWinBanner);
    });
    AudioApi.play({ type: ISongs.SONG_025_04_TotalWinBanner, stopPrev: true });

    totalWinDelay.start();

    const messageWinBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    }).init();

    this.messageBanner = messageWinBanner;
    this.scaleBanner();

    this.addChild(messageWinBanner);
  }

  override resize(width: number, height: number) {
    this.windowSize = { width, height };
    this.scaleBanner();
  }

  private scaleBanner() {
    const isLandScape = this.windowSize.width > this.windowSize.height;
    if (this.messageBanner !== null && setIsOpenedMessageBanner()) {
      this.messageBanner.scale.set(isLandScape ? 1 : 0.95);
    }
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }

  override onModeChange(settings: { mode: GameMode }): void {
    if (isRegularMode(settings.mode)) {
      updateCoinValueAfterBonuses();
    }
  }
}

export default GameView;
