import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

export const WIN_TITLE_Y = SLOTS_CONTAINER_HEIGHT / 2 - 100;
export const WIN_TITLE_X = SLOTS_CONTAINER_WIDTH / 2;

export const WIN_LABEL_Y = SLOTS_CONTAINER_HEIGHT / 2 - 100;
export const WIN_LABEL_X = SLOTS_CONTAINER_WIDTH / 2;

export const HAMMER_ANIMATION_REEL_DELAY = 100;
export const HAMMER_ANIMATION_DURATION = 1000;

export const BIG_WIN_JINGLE_DURATION = 3000;
