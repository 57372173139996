export enum ResourceTypes {
  basegameBg1 = 'basegameBg1',
  basegameBg2 = 'basegameBg2',
  freegameBg1 = 'freegameBg1',
  freegameBg2 = 'freegameBg2',
  messagebannerA = 'messagebannerA',
  messagebannerB = 'messagebannerB',
  chain = 'chain',
  logo = 'logo',
  particle = 'particle',
  sparks = 'sparks',
  lineLarge = 'lineLarge',
  lineMedium = 'lineMedium',
  lineShort = 'lineShort',
  holeB = 'holeB',
  holeF = 'holeF',
  symbolA = 'symbolA',
  symbolABlack = 'symbolABlack',
  symbolABlur = 'symbolABlur',
  symbolABlur2 = 'symbolABlur2',
  symbolB = 'symbolB',
  symbolBBlack = 'symbolBBlack',
  symbolBBlur = 'symbolBBlur',
  symbolBBlur2 = 'symbolBBlur2',
  symbolC = 'symbolC',
  symbolCBlack = 'symbolCBlack',
  symbolCBlur = 'symbolCBlur',
  symbolCBlur2 = 'symbolCBlur2',
  symbolD = 'symbolD',
  symbolDBlack = 'symbolDBlack',
  symbolDBlur = 'symbolDBlur',
  symbolDBlur2 = 'symbolDBlur2',
  symbolE = 'symbolE',
  symbolEBlack = 'symbolEBlack',
  symbolEBlur = 'symbolEBlur',
  symbolEBlur2 = 'symbolEBlur2',
  symbolF = 'symbolF',
  symbolFBlack = 'symbolFBlack',
  symbolFBlur = 'symbolFBlur',
  symbolFBlur2 = 'symbolFBlur2',
  symbolG = 'symbolG',
  symbolGBlack = 'symbolGBlack',
  symbolGBlur = 'symbolGBlur',
  symbolGBlur2 = 'symbolGBlur2',
  symbolH = 'symbolH',
  symbolHBlack = 'symbolHBlack',
  symbolHBlur = 'symbolHBlur',
  symbolHBlur2 = 'symbolHBlur2',
  symbolHole = 'symbolHole',
  symbolI = 'symbolI',
  symbolIBlack = 'symbolIBlack',
  symbolIBlur = 'symbolIBlur',
  symbolIBlur2 = 'symbolIBlur2',
  symbolSc = 'symbolSc',
  symbolScBlack = 'symbolScBlack',
  symbolScBlur = 'symbolScBlur',
  symbolScBlur2 = 'symbolScBlur2',
  symbolWl = 'symbolWl',
  symbolWlBlack = 'symbolWlBlack',
  symbolWlBlur = 'symbolWlBlur',
  symbolWlBlur2 = 'symbolWlBlur2',
  symbolWlL2 = 'symbolWlL2',
  symbolWlL2Black = 'symbolWlL2Black',
  symbolWlL2Blur = 'symbolWlL2Blur',
  symbolWlL2Blur2 = 'symbolWlL2Blur2',
  symbolWlL3 = 'symbolWlL3',
  symbolWlL3Black = 'symbolWlL3Black',
  symbolWlL3Blur = 'symbolWlL3Blur',
  symbolWlL3Blur2 = 'symbolWlL3Blur2',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  introBg = 'introBg',
  textLogo = 'textLogo',
}
