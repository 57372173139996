import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { IUserBalance } from '../../global';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { countCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.symbolSc)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.symbolWl || slug === ResourceTypes.symbolSc;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;
  const cautionaryNoteData: IPaytableData[] = config.cautionaryNotePayTableData;

  const { currency } = balance;
  return (
    <div>
      <h1 className={styles.title}>{t('infoTabPayTable')}</h1>
      <div className={`${styles['paytable-list']} ${styles.wild_or_scatter}`}>
        {data.map(
          ({ combos, slug }) =>
            hasTranslate(slug) && (
              <div key={slug} className={`${styles['paytable-list__item']} ${styles.wild_or_scatter}`}>
                <div className={styles.img}>
                  <div className={styles.image__title}>
                    {i18n.exists(`infoPayTable.${slug}`) && t(`infoPayTable.${slug}`, '')}
                  </div>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                </div>
                <table className={styles.content}>
                  <tbody>
                    {combos.map((i) => (
                      <tr key={i.pattern}>
                        <td>
                          <span className={styles.multiplier}>{i.pattern} </span>
                          {formatNumber({
                            currency,
                            value: calcMultiplier(i.multiplier, betAmount, slug),
                            showCurrency: showCurrency(currency),
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ),
        )}
      </div>

      <div className={styles['paytable-list']}>
        {data.map(
          ({ combos, slug }) =>
            !hasTranslate(slug) && (
              <div key={slug} className={styles['paytable-list__item']}>
                <div className={styles.img}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                </div>
                <table className={styles.content}>
                  <tbody>
                    {combos.map((i) => (
                      <tr key={i.pattern}>
                        <td>
                          <span className={styles.multiplier}>{i.pattern} </span>
                          {formatNumber({
                            currency,
                            value: calcMultiplier(i.multiplier, betAmount, slug),
                            showCurrency: showCurrency(currency),
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ),
        )}
      </div>
      <div className={styles['payTable-cautionarySymbolList']}>
        {cautionaryNoteData.map(({ combos, slug }) => (
          <div key={slug} className={styles['payTable-cautionarySymbolList__item']}>
            <div className={styles.img}>
              <div>{i18n.exists(`infoPayTable.${'symbolWl'}`) && t(`infoPayTable.${'symbolWl'}`, '')}</div>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            </div>
            <table className={styles.content}>
              <tbody>
                {combos.map((i) => (
                  <tr key={i.pattern}>
                    <td>
                      <span className={styles.multiplier}>{i.pattern} </span>
                      {formatNumber({
                        currency,
                        value: calcMultiplier(i.multiplier, betAmount, slug),
                        showCurrency: showCurrency(currency),
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <p
          className={styles.p}
          dangerouslySetInnerHTML={{
            __html: t('infoPayTableCautionaryNote'),
          }}
        />
      </div>
    </div>
  );
};

export default PaytableComponent;
