import { ISongs, SlotId, SymbolAllAnimationNames, WildSymbolLifeCount } from '../../config';
import { ResourceTypes } from '../../resources.d';

type MoleSymbolType = 'empty' | 'stop' | 'blur1' | 'blur2';

export const moleSlotScales = [0.85, 0.9, 0.95, 1];
export const moleSlotYPositions = [0, 155, 310, 500];
export const moleSlotXPositionOffsets: number[][] = [
  [60, 30, 0, -30, -60],
  [32, 16, 0, -16, -32],
  [14, 7, 0, -7, -14],
  [-14, -7, 0, 7, 14],
];

export const moleSpriteAnimationSymbolTypes: Record<SlotId, MoleSymbolType[]> = {
  [SlotId.A]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.B]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.C]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.D]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.E]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.F]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.G]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.H]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.I]: ['stop', 'stop', 'blur1', 'blur2'],
  [SlotId.WL]: ['stop', 'stop', 'stop', 'blur1', 'blur2'],
  [SlotId.SC]: ['stop', 'stop', 'stop', 'blur1', 'blur2'],
};

export const moleInSpriteTextures: Record<SlotId, Record<MoleSymbolType, ResourceTypes>> = {
  [SlotId.A]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolA,
    blur1: ResourceTypes.symbolABlur,
    blur2: ResourceTypes.symbolABlur2,
  },
  [SlotId.B]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolB,
    blur1: ResourceTypes.symbolBBlur,
    blur2: ResourceTypes.symbolBBlur2,
  },
  [SlotId.C]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolC,
    blur1: ResourceTypes.symbolCBlur,
    blur2: ResourceTypes.symbolCBlur2,
  },
  [SlotId.D]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolD,
    blur1: ResourceTypes.symbolDBlur,
    blur2: ResourceTypes.symbolDBlur2,
  },
  [SlotId.E]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolE,
    blur1: ResourceTypes.symbolEBlur,
    blur2: ResourceTypes.symbolEBlur2,
  },
  [SlotId.F]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolF,
    blur1: ResourceTypes.symbolFBlur,
    blur2: ResourceTypes.symbolFBlur2,
  },
  [SlotId.G]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolG,
    blur1: ResourceTypes.symbolGBlur,
    blur2: ResourceTypes.symbolGBlur2,
  },
  [SlotId.H]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolH,
    blur1: ResourceTypes.symbolHBlur,
    blur2: ResourceTypes.symbolHBlur2,
  },
  [SlotId.I]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolI,
    blur1: ResourceTypes.symbolIBlur,
    blur2: ResourceTypes.symbolIBlur2,
  },
  [SlotId.WL]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolWl,
    blur1: ResourceTypes.symbolWlBlur,
    blur2: ResourceTypes.symbolWlBlur2,
  },
  [SlotId.SC]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolSc,
    blur1: ResourceTypes.symbolScBlur,
    blur2: ResourceTypes.symbolScBlur2,
  },
};

export const moleInStickyWildSpriteTextures: Record<WildSymbolLifeCount, Record<MoleSymbolType, ResourceTypes>> = {
  [3]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolWlL3,
    blur1: ResourceTypes.symbolWlL3Blur,
    blur2: ResourceTypes.symbolWlL3Blur2,
  },
  [2]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolWlL2,
    blur1: ResourceTypes.symbolWlL2Blur,
    blur2: ResourceTypes.symbolWlL2Blur2,
  },
  [1]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolWl,
    blur1: ResourceTypes.symbolWlBlur,
    blur2: ResourceTypes.symbolWlBlur2,
  },
  [0]: {
    empty: ResourceTypes.symbolHole,
    stop: ResourceTypes.symbolWl,
    blur1: ResourceTypes.symbolWlBlur,
    blur2: ResourceTypes.symbolWlBlur2,
  },
};

export const moleNormalSpinSlotIds = [
  SlotId.A,
  SlotId.B,
  SlotId.C,
  SlotId.D,
  SlotId.E,
  SlotId.F,
  SlotId.G,
  SlotId.H,
  SlotId.I,
  SlotId.WL,
];

// TODO mix scatters moderately
export const moleAnticipationSpinSlotIds = [
  SlotId.A,
  SlotId.B,
  SlotId.SC,
  SlotId.C,
  SlotId.D,
  SlotId.SC,
  SlotId.E,
  SlotId.F,
  SlotId.SC,
  SlotId.G,
  SlotId.H,
  SlotId.SC,
  SlotId.I,
  SlotId.WL,
  SlotId.SC,
];

export const freeSpinWildAnimationNames: {
  [key in WildSymbolLifeCount]: {
    stop?: SymbolAllAnimationNames;
    hammerHit?: SymbolAllAnimationNames;
    afterLoop?: SymbolAllAnimationNames;
  };
} = {
  [3]: {
    stop: 'symbol_stop_wild_life3',
  },
  [2]: {
    stop: 'symbol_stop_wild_life3',
    hammerHit: 'symbol_win1_wild_life2',
    afterLoop: 'symbol_spin_wild_life2',
  },
  [1]: {
    stop: 'symbol_stop_wild_life3',
    hammerHit: 'symbol_win1_wild_life1',
    afterLoop: 'symbol_spin_wild_life1',
  },
  [0]: {
    hammerHit: 'symbol_win1_wild',
  },
};

export const baseGameScatterStopSoundList = [
  {
    sound: ISongs.SONG_025_11_Scatter_01,
    reelId: 0,
  },
  {
    sound: ISongs.SONG_025_12_Scatter_02,
    reelId: 2,
  },
  {
    sound: ISongs.SONG_025_13_Scatter_03,
    reelId: 4,
  },
];

export const freeSpinsScatterStopSoundList = [
  ISongs.SONG_025_11_Scatter_01,
  ISongs.SONG_025_12_Scatter_02,
  ISongs.SONG_025_13_Scatter_03,
];
