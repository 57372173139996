export const spineFile = [
  'ambient',
  'chain_fluctuation',
  'coin_few',
  'effect_frame_nearmiss',
  'hammer',
  'hit_effect',
  'nearmiss',
  'payline',
  'symbol_all',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  ambient: { skins: 'default'; animations: 'ambient_bg' | 'ambient_fg' };
  chain_fluctuation: { skins: 'default'; animations: 'chain_fluctuation' };
  coin_few: { skins: 'default'; animations: 'coin_few' };
  effect_frame_nearmiss: { skins: 'default'; animations: 'effect_frame_nearmiss' | 'effect_frame_nearmiss_finish' };
  hammer: { skins: 'default'; animations: 'hammer' };
  hit_effect: {
    skins: 'default';
    animations: 'hit_effect_a' | 'hit_effect_b' | 'hit_effect_c' | 'hit_effect_d' | 'hit_effect_e' | 'hit_effect_f';
  };
  nearmiss: {
    skins: 'default';
    animations: 'effect_frame_nearmiss' | 'effect_frame_nearmiss_stop' | 'effect_frame_nearmiss_stop_win';
  };
  payline: { skins: 'default'; animations: 'payline_effect' };
  symbol_all: {
    skins: 'default';
    animations:
      | 'symbol_spin_wild_life1'
      | 'symbol_spin_wild_life2'
      | 'symbol_stop_a'
      | 'symbol_stop_b'
      | 'symbol_stop_c'
      | 'symbol_stop_d'
      | 'symbol_stop_e'
      | 'symbol_stop_f'
      | 'symbol_stop_g'
      | 'symbol_stop_h'
      | 'symbol_stop_i'
      | 'symbol_stop_sc'
      | 'symbol_stop_wild'
      | 'symbol_stop_wild_life3'
      | 'symbol_win1_a'
      | 'symbol_win1_b'
      | 'symbol_win1_c'
      | 'symbol_win1_d'
      | 'symbol_win1_e'
      | 'symbol_win1_f'
      | 'symbol_win1_g'
      | 'symbol_win1_h'
      | 'symbol_win1_i'
      | 'symbol_win1_sc'
      | 'symbol_win1_wild'
      | 'symbol_win1_wild_life1'
      | 'symbol_win1_wild_life2'
      | 'symbol_win2_a'
      | 'symbol_win2_b'
      | 'symbol_win2_c'
      | 'symbol_win2_d'
      | 'symbol_win2_e'
      | 'symbol_win2_f'
      | 'symbol_win2_g'
      | 'symbol_win2_h'
      | 'symbol_win2_i'
      | 'symbol_win2_sc'
      | 'symbol_win2_wild';
  };
}

export const ENABLE_MERGED_ATRAS = false;

export const preloadSpineTextures = [];

export const mobileVersionSpineFile = ['coins', 'symbol_all'];
