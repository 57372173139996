import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes } from '../../global.d';
import { setIsSlotBusy, setIsSpinInProgress, setProgress, setSlotConfig } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { eventManager } from '../../slotMachine/config';
import { IntroScreen } from '../../slotMachine/introScreen';
import { wrap } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

const GameScreen: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn } = data!;
  const [, setShowContent] = useState(false);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });
    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resizeHandler = (): void => {
      const userAgent = navigator.userAgent;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          resize();
        }, 50);
      } else {
        resize();
      }
    };

    IntroScreen.init(application);

    eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      resize();
    });

    resize();

    window.addEventListener(EventTypes.RESIZE, resizeHandler);
    return () => window.removeEventListener(EventTypes.RESIZE, resizeHandler);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles.canvas} ${progress.wasLoaded ? '' : styles.intro}`} />
      <EventListener />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsPopup />
      <Spin />
    </>
  );
};

export default GameScreen;
