import { Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ParticleEmitterContainer } from '../components/particle';
import { StrictSpine } from '../components/spine/spine';
import { ViewContainer } from '../components/viewContainer';
import { GAME_CONTAINER_WIDTH, eventManager } from '../config';

import { particleConfig } from './config';

class Foreground extends ViewContainer {
  private objectSprite: Sprite;

  private ambient: StrictSpine<'ambient'>;

  private particles: ParticleEmitterContainer;

  private gameMode: GameMode;

  private windowSize = { width: 0, height: 0 };

  constructor() {
    super();
    this.sortableChildren = true;
    this.gameMode = GameMode.FREE_SPINS;

    this.objectSprite = this.initObjectSprite();
    this.addChild(this.objectSprite);

    this.ambient = this.initAmbient();
    this.ambient.state.setAnimation(0, 'ambient_bg', true);
    this.addChild(this.ambient);

    this.particles = new ParticleEmitterContainer(particleConfig);
    this.particles.stop(true);
    this.addChild(this.particles);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private initObjectSprite() {
    const sprite = new Sprite(Texture.from(ResourceTypes.basegameBg2));
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  private initAmbient() {
    const spine = new StrictSpine('ambient');
    spine.pivot.x = -500;
    return spine;
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (settings.mode === GameMode.FREE_SPINS) {
      this.objectSprite.texture = Texture.from(ResourceTypes.freegameBg2);
      this.ambient.state.setAnimation(0, 'ambient_fg', true);
      this.particles.start();
    } else {
      this.objectSprite.texture = Texture.from(ResourceTypes.basegameBg2);
      this.ambient.state.setAnimation(0, 'ambient_bg', true);
      this.particles.stop();
    }
    this.gameMode = settings.mode;
  }

  override resize(width: number, height: number): void {
    this.windowSize = { width, height };
    this.objectSprite.x = GAME_CONTAINER_WIDTH / 2;
    if (width > height) {
      this.objectSprite.y = 150;
      this.ambient.y = 150;
    } else {
      this.objectSprite.y = this.gameMode === GameMode.FREE_SPINS ? 150 : 0;
      this.ambient.y = this.gameMode === GameMode.FREE_SPINS ? 150 : 0;
    }
  }

  private resizeGameContainer(
    width: number,
    height: number,
    _x: number,
    _y: number,
    _scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    if (width > height) {
      const scale = this.windowSize.width / width;

      this.objectSprite.scale.set(scale);
      this.ambient.scale.set(scale);
      this.particles.scale.set(scale);
    } else {
      const scale =
        this.gameMode === GameMode.FREE_SPINS
          ? (this.windowSize.height / height) * 0.8
          : this.windowSize.height / height;

      this.objectSprite.scale.set(scale);
      this.ambient.scale.set(scale);
      this.particles.scale.set(scale);
    }
  }
}
export default Foreground;
