import { t } from 'i18next';
import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig } from '../../gql/cache';
import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const ChainFeature: React.VFC = () => {
  const { t } = useTranslation();

  const chainFeatureDesc: string[] = t('infoChainFeatureDesc', {
    returnObjects: true,
  });

  return (
    <section>
      <h1 className={styles.subtitle}>{t('infoChainFeatureTitle')}</h1>
      <div className={styles.col}>
        {chainFeatureDesc.map((v) => (
          <p className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();
  const freeSpinFeatureDesc: string[] = t('infoFreeSpinFeatureDesc', {
    returnObjects: true,
  });
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }

  return (
    <section>
      <h1 className={styles.subtitle}>{t('infoFreeSpinFeatureTitle')}</h1>
      <div className={styles.col}>
        {freeSpinFeatureDesc.map((v) => (
          <p className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const buyFeatureDesc: string[] = t('infoBuyFeatureDesc', {
    returnObjects: true,
  });
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon!));
  }, []);

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section>
      <h1 className={styles.subtitle}>{t('infoBuyFeatureTitle')}</h1>
      <div className={styles.buy_feature}>
        <img className={styles.buy_feature__img} draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {buyFeatureDesc.map((v) => (
            <p className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div>
      <h1 className={styles.title}>{t('infoTabFeatures')}</h1>
      <div>
        <ChainFeature />
        <FreeSpinFeature />
        <BuyFeatureFunction />
      </div>
    </div>
  );
};

export default Features;
