import { ITextStyle } from 'pixi.js';

import { Variables } from '../../config';

const fontFamily = Variables.FONT_FAMILY;

export const layoutStyles = {
  fontFamily: fontFamily,
  align: 'center',
  fontSize: 26,
  fontWeight: '700',
  fill: '#FFFFFF',
};

export const buttonTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: fontFamily,
  fontSize: 20,
  fontWeight: 'bold',
  //fill: '#ffffff',
};

export const freeRoundsTextStyles: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: fontFamily,
  fontSize: 20,
  fontWeight: 'bold',
  fill: '#ffffff',
};

export const LAYOUT_OPTIONS = {
  width: 430,
  height: 295,
  border: 1,
  borderRadius: 20,
  margin: 20,
  padding: 20,
  borderColor: 0xffcc48,
  buttonColor: 0xffcc48,
  bgColor: 0x000000,
  textStyle: layoutStyles,
  traceIdTextStyle: { ...layoutStyles, fontSize: 16, fill: '#ccc' },
  traceIdOpacity: 0.3,
};
