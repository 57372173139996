import { Container, Sprite, Texture } from 'pixi.js';

import {
  MAPPED_FREE_SPINS_WILD_SYMBOLS,
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_ANIMATIONS,
  SlotId,
  WildSymbolLifeCount,
} from '../../config';
import { setGameMode } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { getRandomNumber, isFreeSpinMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import { StrictSpine } from '../components/spine/spine';
import { APPLICATION_FPS } from '../config';

import {
  freeSpinWildAnimationNames,
  moleAnticipationSpinSlotIds,
  moleInSpriteTextures,
  moleInStickyWildSpriteTextures,
  moleNormalSpinSlotIds,
  moleSpriteAnimationSymbolTypes,
} from './config';

const MAX_LIFE_COUNT: WildSymbolLifeCount = 3;

class MoleSlot extends Container {
  private slotId: SlotId;

  private sprite: Sprite;

  private bodySpine: StrictSpine<'symbol_all'>;

  private payLineSpine: StrictSpine<'payline'>;

  public wildSymbolLifeCount: WildSymbolLifeCount = 0;

  constructor(slotId: SlotId) {
    super();
    this.slotId = slotId;

    const isStickyWild = this.isStickyWild(slotId);

    {
      if (isStickyWild) {
        this.wildSymbolLifeCount = MAX_LIFE_COUNT;
      }

      this.sprite = new Sprite(
        Texture.from(isStickyWild ? MAPPED_FREE_SPINS_WILD_SYMBOLS[this.wildSymbolLifeCount] : MAPPED_SYMBOLS[slotId]),
      );
      this.sprite.anchor.set(0.5, 0.5);
      this.sprite.visible = true;
      this.addChild(this.sprite);
    }

    {
      this.bodySpine = new StrictSpine('symbol_all');
      this.bodySpine.visible = false;
      this.addChild(this.bodySpine);
    }

    {
      this.payLineSpine = new StrictSpine('payline');
      this.payLineSpine.visible = false;
      this.addChild(this.payLineSpine);
    }
  }

  private isStickyWild(sloId: SlotId) {
    const isFreeSpin = isFreeSpinMode(setGameMode());
    if (sloId === SlotId.WL && isFreeSpin) {
      return true;
    }
    return false;
  }

  private createHoleInSpriteTextures(slotId: SlotId) {
    const textures = moleSpriteAnimationSymbolTypes[slotId].map((symbolType) => {
      return Texture.from(
        this.isStickyWild(slotId)
          ? moleInStickyWildSpriteTextures[MAX_LIFE_COUNT][symbolType]
          : moleInSpriteTextures[slotId][symbolType],
      );
    });
    textures.push(Texture.from(ResourceTypes.symbolHole));
    return textures;
  }

  private createHoleOutSpriteTextures(slotId: SlotId) {
    const textures = this.createHoleInSpriteTextures(slotId);
    return [...textures].reverse();
  }

  private getHoleInAnimation(slotId: SlotId, fps = APPLICATION_FPS) {
    const animation = new SpriteAnimation({}, this.createHoleInSpriteTextures(slotId), fps);
    animation.spriteAnimation.anchor.set(0.5);
    animation.addOnStart(() => {
      this.addChild(animation.spriteAnimation);
    });

    animation.addOnSkip(() => {
      this.removeChild(animation.spriteAnimation);
    });

    animation.addOnComplete(() => {
      this.removeChild(animation.spriteAnimation);
    });

    return animation;
  }

  public getHoleOutAnimation(slotId: SlotId, fps = APPLICATION_FPS) {
    const animation = new SpriteAnimation({}, this.createHoleOutSpriteTextures(slotId), fps);
    animation.spriteAnimation.anchor.set(0.5);

    animation.addOnStart(() => {
      this.addChild(animation!.spriteAnimation);
    });

    animation.addOnSkip(() => {
      this.removeChild(animation!.spriteAnimation);
    });

    animation.addOnComplete(() => {
      this.removeChild(animation.spriteAnimation);
    });

    return animation;
  }

  public createLoopHoleInOutAnimation(fps = 30, loopCount = 1, isAnticipation = false) {
    const animationChain = new AnimationChain();

    animationChain.addOnStart(() => {
      this.idle = false;
    });

    for (let i = 0; i < loopCount; i++) {
      let slotId = this.slotId;

      slotId = isAnticipation
        ? moleAnticipationSpinSlotIds[getRandomNumber(moleAnticipationSpinSlotIds.length)]!
        : moleNormalSpinSlotIds[getRandomNumber(moleNormalSpinSlotIds.length)]!;

      const holeOut = this.getHoleOutAnimation(slotId, fps);
      const holeIn = this.getHoleInAnimation(slotId, fps);
      animationChain.appendAnimation(holeOut);
      animationChain.appendAnimation(holeIn);
    }

    return animationChain;
  }

  public createRollingAnimation(fps = 30, duration = 15000, loopCount = 100, isAnticipation = false) {
    const loopAnimation = Tween.createDelayAnimation(duration);
    const rolling = this.createLoopHoleInOutAnimation(fps, loopCount, isAnticipation);

    loopAnimation.addOnStart(() => {
      rolling.start();
    });
    loopAnimation.addOnComplete(() => {
      rolling.animations.forEach((a) => {
        if (!a.isLoop) a.ended = true;
        a.complete.forEach((callback) => callback());
      });
    });
    loopAnimation.addOnSkip(() => {
      rolling.animations.forEach((a) => {
        a.skip();
      });
    });
    return loopAnimation;
  }

  public createStopAnimation(slotId: SlotId, fps = 30) {
    const animationChain = new AnimationChain();

    // spriteAnimation
    {
      const holeOutAnimation = this.getHoleOutAnimation(slotId, fps);
      holeOutAnimation.addOnComplete(() => {
        this.slotId = slotId;
        if (this.isStickyWild(slotId)) {
          this.wildSymbolLifeCount = MAX_LIFE_COUNT;
        }
        this.changeTexture(slotId);
        this.tint = 0xffffff;
        this.startStopAnimation(slotId);
      });
      animationChain.appendAnimation(holeOutAnimation);
    }

    // spineAnimation→sprite
    {
      const duration =
        (this.bodySpine.skeleton.data.findAnimation(
          this.isStickyWild(slotId) ? freeSpinWildAnimationNames[3].stop! : MAPPED_SYMBOLS_ANIMATIONS[slotId].stop!,
        )?.duration ?? 0) * 1000;
      const stopIdleDelay = Tween.createDelayAnimation(duration);
      stopIdleDelay.addOnComplete(() => {
        this.idle = true;
      });
      animationChain.appendAnimation(stopIdleDelay);
    }

    return animationChain;
  }

  private startStopAnimation(slotId: SlotId) {
    this.idle = false;
    this.bodySpine.state.setAnimation(
      0,
      this.isStickyWild(slotId) ? freeSpinWildAnimationNames[3].stop! : MAPPED_SYMBOLS_ANIMATIONS[slotId].stop!,
      false,
    );
  }

  private starHammerBeatenAnimation(lifeCount: WildSymbolLifeCount = 2) {
    this.idle = false;
    if (this.isStickyWild(this.slotId)) {
      this.bodySpine.state.setAnimation(0, freeSpinWildAnimationNames[lifeCount].hammerHit!, false);
      if (freeSpinWildAnimationNames[lifeCount].afterLoop) {
        this.bodySpine.state.addAnimation(0, freeSpinWildAnimationNames[lifeCount].afterLoop!, true, 0);
      }
    } else {
      this.bodySpine.state.setAnimation(0, MAPPED_SYMBOLS_ANIMATIONS[this.slotId].hammerHit!, false);
    }
  }

  public getHammerBeatenAnimation(lifeCount: WildSymbolLifeCount = 2) {
    const duration =
      (this.bodySpine.skeleton.data.findAnimation(MAPPED_SYMBOLS_ANIMATIONS[this.slotId].hammerHit!)?.duration ?? 0) *
      1000;
    const animation = Tween.createDelayAnimation(duration);

    animation.addOnStart(() => {
      this.starHammerBeatenAnimation(lifeCount);
    });

    animation.addOnSkip(() => {
      if (!this.isStickyWild(this.slotId)) {
        this.idle = true;
        this.bodySpine.state.setEmptyAnimation(0, 0);
      } else if (this.wildSymbolLifeCount === 1) {
        this.idle = true;
        this.bodySpine.state.setEmptyAnimation(0, 0);
      }
    });

    return animation;
  }

  private startWinAnimation() {
    this.idle = false;
    this.bodySpine.state.setAnimation(0, MAPPED_SYMBOLS_ANIMATIONS[this.slotId].win!, false);
    this.payLineSpine.state.setAnimation(0, 'payline_effect', true);
  }

  private changeTexture(slotId: SlotId) {
    const resource = this.isStickyWild(slotId)
      ? moleInStickyWildSpriteTextures[this.wildSymbolLifeCount]['stop']
      : moleInSpriteTextures[slotId]['stop']!;
    this.sprite.texture = Texture.from(resource);
  }

  public changeWildStickyTexture() {
    const resource = moleInStickyWildSpriteTextures[this.wildSymbolLifeCount]['stop'];
    this.sprite.texture = Texture.from(resource);
  }

  public getWinAnimation() {
    const duration =
      (this.bodySpine.skeleton.data.findAnimation(MAPPED_SYMBOLS_ANIMATIONS[this.slotId].win!)?.duration ?? 0) * 1000;
    const animation = Tween.createDelayAnimation(duration);
    animation.addOnStart(() => {
      this.startWinAnimation();
    });
    animation.addOnSkip(() => {
      this.idle = true;
      this.bodySpine.state.setEmptyAnimation(0, 0);
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });
    animation.addOnComplete(() => {
      this.idle = true;
      this.bodySpine.state.setEmptyAnimation(0, 0);
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });
    return animation;
  }

  public getPayLineEffectAnimation() {
    const duration =
      (this.bodySpine.skeleton.data.findAnimation(MAPPED_SYMBOLS_ANIMATIONS[this.slotId].win!)?.duration ?? 0) * 1000;
    const animation = Tween.createDelayAnimation(duration);

    animation.addOnStart(() => {
      this.payLineSpine.state.setAnimation(0, 'payline_effect', true);
    });
    animation.addOnSkip(() => {
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });
    animation.addOnComplete(() => {
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });
    return animation;
  }

  public getScatterWinAnimation() {
    const duration =
      (this.bodySpine.skeleton.data.findAnimation(MAPPED_SYMBOLS_ANIMATIONS[SlotId.SC].win!)?.duration ?? 0) * 1000;
    const animation = Tween.createDelayAnimation(duration);

    animation.addOnStart(() => {
      this.idle = false;
      this.bodySpine.state.setAnimation(0, MAPPED_SYMBOLS_ANIMATIONS[SlotId.SC].win!, false);
      this.payLineSpine.state.setAnimation(0, 'payline_effect', false);
    });

    animation.addOnComplete(() => {
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });

    animation.addOnSkip(() => {
      this.idle = true;
      this.bodySpine.state.setEmptyAnimation(0, 0);
      this.payLineSpine.state.setEmptyAnimation(0, 0);
    });
    return animation;
  }

  public skip(): void {
    this.bodySpine.state.setEmptyAnimation(0, 0);
  }

  public getSlotId() {
    return this.slotId;
  }

  public set idle(value: boolean) {
    this.sprite.visible = value;
    this.bodySpine.visible = !value;
    this.payLineSpine.visible = !value;
  }

  public get idle(): boolean {
    return this.sprite.visible;
  }

  public set tint(value: number) {
    this.sprite.tint = value;
    this.bodySpine.tint = value;
  }
}

export default MoleSlot;
