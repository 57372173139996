import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_X,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_Y,
} from './config';
import { totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  protected banner?: PIXI.Sprite;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.y = MESSAGE_WIN_BANNER_Y;
  }

  public override init(): MessageWinBanner {
    super.init();
    this.banner = this.initBanner();
    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.addChild(this.banner, this.title, this.totalWin);
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messagebannerB));
    banner.anchor.set(0.5, 0);
    return banner;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.anchor.set(0.5, 0.5);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    updateTextScale(winTitle, this.banner!.width - 250, 250);
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.anchor.set(0.5, 0);
    winTotal.x = MESSAGE_WIN_BANNER_WIN_AMOUNT_X;
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }
}
