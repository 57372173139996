import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { SpineFile, SpineInterface } from '../../../config/spine.generated';

export class StrictSpine<SF extends SpineFile = SpineFile> extends Spine<SpineInterface[SF]['animations']> {
  constructor(spineName: SF) {
    super(Loader.shared.resources[spineName]!.spineData!);
  }
}
