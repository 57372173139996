import _ from 'lodash';

import { EventTypes } from '../../global.d';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { eventManager } from '../config';

export class MoleSpinAnimation extends AnimationChain {
  constructor(options: { staringAnimation: Animation; rollingAnimation: Animation; endingAnimation?: Animation }) {
    super();
    this.appendAnimation(options.staringAnimation);
    this.appendAnimation(options.rollingAnimation);
    if (options.endingAnimation) {
      this.appendAnimation(options.endingAnimation);
    }
    eventManager.addListener(EventTypes.BREAK_SPIN_ANIMATION, this.breakSpinAnimation.bind(this));
  }

  public getStarting(): Animation {
    return this.animations[0] as Animation;
  }

  public getRolling(): Animation {
    return this.animations[1] as Animation;
  }

  public getEnding(): Animation {
    return this.animations[2] as Animation;
  }

  private breakSpinAnimation() {
    _.forEach(this.animations, (animation) => {
      if (this.animations.indexOf(animation)) {
        this.animations.splice(this.animations.indexOf(animation), 1);
      }
    });
  }
}
