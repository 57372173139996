import { captureException } from '@sentry/react';
import { isMobile } from 'pixi.js';

import { SlotId } from '../config';
import { EventTypes, GameMode, bonusIds, reelSets } from '../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setSlotConfig } from '../gql';
import SpineAnimation from '../slotMachine/animations/spine';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
  eventManager,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lineSet.coinAmountMultiplier)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  captureException(error);
  throw error;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};

export const destroySpine = (spine: SpineAnimation): void => {
  window.setTimeout(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy();
    }
  }, 0);
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.SC;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isBuyFeaturePopupOpened,
  isAutoSpins,
  isFadeOut,
  isBrokenBuyFeature,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isBuyFeaturePopupOpened: boolean;
  isAutoSpins: boolean;
  isFadeOut: boolean;
  isBrokenBuyFeature: boolean;
}): boolean => {
  if (isBrokenBuyFeature) {
    return false;
  }

  if (isAutoSpins) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    if (isOpenedMessageBanner) {
      return true;
    }
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isFadeOut) {
    return false;
  }

  return true;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }
  return GameMode.REGULAR;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(bonusIds)) {
    if (id === bonusId) {
      return Number(gameMode) as GameMode;
    }
  }
  return GameMode.REGULAR;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return reelSets[GameMode.REGULAR] === reelSetId;
};

export const isBuyFeatureReelSet = (reelSetId: string): boolean => {
  return reelSets[GameMode.BUY_FEATURE] === reelSetId;
};

export const countStage = (bet: number, win: number): WinStages => {
  const multiplier = win / bet;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export function getRandomNumber(max: number, min = 0): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const queryParams = new URLSearchParams(window.location.search);

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth < 1000;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};

export const isNotNullOrUndefined = <T>(v?: T | null): v is T => null != v;

/**
 * ⚠️CAUTION⚠️
 * This function ignores null checks and is unsafe
 * @param value Nullable value
 * @returns value typecast to NonNullable
 */
export function getNonNullableValue<T>(value: T): RecursiveNonNullable<T> {
  return value as RecursiveNonNullable<T>;
}

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};
